import { FC } from 'react';

import { Button } from 'components/ui';

interface IDeleteStreamModalProps {
  onClose: () => void;
  onBack: () => void;
}

export const DeleteStreamModal: FC<IDeleteStreamModalProps> = ({
  onClose,
  onBack,
}) => {
  const handleDelete = () => {
    onClose();
  };

  return (
    <>
      <div className="flex flex-col gap-[12px] items-center">
        <span className="tpg-h4">Вы уверены, что хотите удалить запись?</span>
        <span className="tpg-b2 text-tpg_base">
          Вы можете потерять ценные данные.
        </span>
      </div>
      <div className="flex flex-row gap-[12px]">
        <Button
          onClick={onBack}
          title="Нет, я передумал"
          className="w-full hover:bg-dark_product bg-inherit border-2 border-solid border-dark_product"
        />
        <Button
          onClick={handleDelete}
          title="Да, я хочу удалить"
          className="w-full"
        />
      </div>
    </>
  );
};
