import { useCallback, useRef } from 'react';
import {
  LocalParticipant,
  LocalTrack,
  ScreenShareCaptureOptions,
  Track,
} from 'livekit-client';

export const useScreenTracks = (
  localParticipant: LocalParticipant,
  options: {
    onError: () => void;
    onSuccess: () => void;
    screenTrackOptions?: ScreenShareCaptureOptions;
  }
) => {
  const { onError, onSuccess, screenTrackOptions } = options;

  const videoEl = useRef<HTMLVideoElement>(null);
  const videoTrack = useRef<LocalTrack>();
  const audioTrack = useRef<LocalTrack>();

  const createScreenTracks = useCallback(async () => {
    try {
      const screenTracks = await localParticipant.createScreenTracks(
        screenTrackOptions
      );

      screenTracks.forEach((track) => {
        if (videoEl.current) {
          if (track.kind === Track.Kind.Video) {
            track.attach(videoEl.current);
            localParticipant.publishTrack(track);
            videoTrack.current = track;
          }

          if (track.kind === Track.Kind.Audio) {
            localParticipant.publishTrack(track);
            audioTrack.current = track;
          }
        }
      });
    } catch (error) {
      onError();
    } finally {
      if (videoTrack.current && audioTrack.current) {
        onSuccess();
      }
    }
  }, []);

  const stopStream = useCallback(() => {
    if (
      localParticipant.trackPublications &&
      videoTrack.current &&
      audioTrack.current
    ) {
      localParticipant.unpublishTracks([
        videoTrack.current,
        audioTrack.current,
      ]);
    }
  }, [localParticipant, videoTrack, audioTrack]);

  return {
    createScreenTracks,
    stopStream,
    videoEl: videoEl,
    audioTrack: audioTrack.current,
    videoTrack: videoTrack.current,
  };
};
