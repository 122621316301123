import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks';
import poster from 'mock/images/stream3.png';
import { recordsSelector } from 'store/slices/records';
import { getClanRecordsThunk } from 'store/slices/records/actions';
import { roomSelector } from 'store/slices/room';
import { getClanRoomsThunk } from 'store/slices/room/actions';
import { ID } from 'types';

import { Card } from 'components/Card';

interface IClansStreamListProps {
  id: ID;
  clan: string;
  place: string;
}

export const ClansStreamList: FC<IClansStreamListProps> = ({
  id,
  clan,
  place,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { rooms } = useAppSelector(roomSelector);
  const { records } = useAppSelector(recordsSelector);

  const clanRecords = records.get(id) || [];
  const clanRooms = rooms.get(id) || [];

  const handleNavigateClan = () => {
    navigate(`/clan/${id}`);
  };

  useEffect(() => {
    dispatch(getClanRoomsThunk(id));
    dispatch(getClanRecordsThunk(id));
  }, []);

  return (
    <div
      className="flex flex-col py-[16px] px-[24px] gap-[12px] bg-dark rounded-[10px] cursor-pointer hover:bg-light"
      onClick={handleNavigateClan}
    >
      <div className="flex justify-between">
        <span className="tpg-b1">{clan}</span>
        <span className="tpg-b2 text-tpg_base">{place}</span>
      </div>
      <div className="flex gap-2">
        <span className="tpg-c1 text-bright_product">{`${clanRooms.length} в эфире`}</span>
        <span className="tpg-c1 text-bright_product">{`${clanRecords.length} в записи`}</span>
      </div>
      <div className="grid grid-cols-2 gap-[4px] w-full">
        {clanRooms.map(({ livekitName, host }) => (
          <Card
            type="stream"
            key={livekitName}
            cardId={livekitName}
            src={poster}
            host={host}
            clanId={id}
          />
        ))}

        {clanRecords.map(({ id: recordId }) => (
          <Card
            type="record"
            key={recordId}
            cardId={recordId}
            tags={[]}
            clanId={id}
          />
        ))}
      </div>
    </div>
  );
};
