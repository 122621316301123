import { AxiosResponse } from 'axios';
import { IRecord } from 'interfaces/record';
import axios from 'services/axios';
import { ID } from 'types';

const MOCK_RECORDS: IRecord[] = [
  {
    id: '001',
    clanId: '',
    startedAt: '',
    endedAt: '',
    publisherId: '',
    publisherName: '',
    room: '',
    srcUrl:
      'https://egress-test.hb.ru-msk.vkcs.cloud/livekit-demo/track-test.mp4',
  },
  {
    id: '002',
    clanId: '',
    startedAt: '',
    endedAt: '',
    publisherId: '',
    publisherName: '',
    room: '',
    srcUrl:
      'https://egress-test.hb.ru-msk.vkcs.cloud/livekit-demo/track-test.mp4',
  },
];

export const getClanRecords = async (clanId: ID) =>
  axios.get(`/clans/${clanId}/recordings`, {
    transformResponse: [
      (data) => {
        const json = JSON.parse(data); // need for transform

        return { clanId, records: MOCK_RECORDS };
      },
    ],
  });

export const getRecord = async (recordId: ID) =>
  ({ data: MOCK_RECORDS[0] } as AxiosResponse<IRecord>); // temporary mocks
