import { FC } from 'react';

import { Tag } from 'components/ui/Tag';

interface IDateTagProps {
  date: (string | null)[];
  setDate: (value: (string | null)[]) => void;
}

export const DateTag: FC<IDateTagProps> = ({ date, setDate }) => (
  <Tag
    label={
      date.length > 1 ? `дата с ${date[0]} по ${date[1]}` : `дата ${date[0]}`
    }
    onRemove={() => setDate([])}
    closeIcon
  >
    <div className="flex flex-row gap-[4px]">
      {date.length > 1 ? (
        <>
          <span className="tpg-c2 whitespace-nowrap">дата</span>
          <span className="tpg-c2 text-tpg_base  whitespace-nowrap">{`c ${date[0]} по ${date[1]}`}</span>
        </>
      ) : (
        <>
          <span className="tpg-c2 whitespace-nowrap">дата</span>
          <span className="tpg-c2 text-tpg_base whitespace-nowrap">{`${date[0]}`}</span>
        </>
      )}
    </div>
  </Tag>
);
