import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { reducersNames } from 'constants/reducers';
import {
  IAttributeEntity,
  IDeleteMode,
  IFeature,
  ILayerBase,
  IMapMediaPreview,
  IMapState,
} from 'interfaces';

import { TMapObjectUnion, ValueOf } from '../../../types';

const initialState: IMapState = {
  features: {
    isFeatureDetailsModalOpen: false,
    isFeaturePreviewPopupOpen: false,
    isNeedListUpdating: false,
    list: [],
    pending: false,
    total: 0,
  },
  filters: undefined,
  geospoof: undefined,
  imagery: undefined,
  currentFeature: null,
  isModified: false,
  deleteMode: null,
  mediaPreviewMode: null,
  selectedFeatures: [],
  isLabelOnMap: true,
  coordsMode: 'wgs',
  operationalRange: [null, null],
  geocoderResultCoordinates: undefined,
  openedPopup: null,
};

const mapSlice = createSlice({
  name: reducersNames.MAP,
  initialState,
  reducers: {
    // DeleteMode actions
    setDeleteMode(state, action: PayloadAction<IDeleteMode | null>) {
      state.deleteMode = action.payload;
    },
    // Media Preview actions
    setMediaPreviewMode(state, action: PayloadAction<IMapMediaPreview | null>) {
      state.mediaPreviewMode = action.payload;
    },
    setCoordsMode(state, action) {
      state.coordsMode = action.payload;
    },
    setOpenedPopup(state, action) {
      state.openedPopup = action.payload;
    },
    addFeatureMedia(state, action) {
      if (state.currentFeature) {
        state.currentFeature.media = [
          ...(state.currentFeature.media || []),
          action.payload,
        ];

        state.isModified = true;
      }
    },
    updateCurrentFeature(
      state: IMapState,
      {
        payload: { field, value },
      }: PayloadAction<{
        field: TMapObjectUnion;
        value:
          | string
          | number
          | null
          | IAttributeEntity
          | ILayerBase
          | IAttributeEntity[]
          | number[];
      }>
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (state.currentFeature && state.currentFeature[field] !== value) {
        if (field === 'object_type') {
          state.currentFeature['event_type'] = null;
        }

        if (field === 'event_type') {
          state.currentFeature['object_type'] = null;
        }

        if (field === 'layer_id') {
          state.currentFeature['isLayerIdChanged'] = true;
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (state.currentFeature[field] as ValueOf<IFeature>) = value;
        state.isModified = true;
      }
    },
    resetStableState(state) {
      state.currentFeature = null;
    },
  },
});

export const { actions: mapActions, reducer: mapReducer } = mapSlice;
