import { useNavigate, useSearchParams } from 'react-router-dom';

import { AddButton } from 'components/AddButton';
import { SwitchWithLabel } from 'components/SwitchWithLabel';

export const RightHeaderContent = () => {
  const [searchParams] = useSearchParams();

  const isShowPlayers = searchParams.get('filter') === 'players';

  const navigate = useNavigate();

  const handleAddClan = () => {
    navigate('/map');
  };

  const handleAddPlayer = () => {
    navigate('/admin-panel/player/new');
  };

  return (
    <div className="flex flex-row gap-[16px]">
      <AddButton
        label={isShowPlayers ? 'Добавить пользователя' : 'Добавить клан'}
        onClick={isShowPlayers ? handleAddPlayer : handleAddClan}
      />
      <SwitchWithLabel label="Список пользователей" />
    </div>
  );
};
