import { useMemo } from 'react';
import { EUserRole } from 'constants/profile';
import { IAccountRole } from 'interfaces';

export const useRoles = (
  permisions: EUserRole[],
  userRoles: IAccountRole[]
) => {
  const clanId = useMemo(
    () =>
      userRoles.find((role) => permisions.includes(role.role_name))?.clan_id,
    [userRoles]
  );

  if (!clanId) return null;

  return clanId;
};
