import { Key, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks';
import { IAccountRole } from 'interfaces';
import { IClanPlayer } from 'interfaces/player';
import { playerSelector } from 'store';
import { getClansThunk } from 'store/slices/clan/actions';
import { getAllPlayersThunk } from 'store/slices/player/actions';
import { rolesTranslateMap, TPlayerTable } from 'types/player';

import Loader from 'components/ui/Loader';
import STable from 'components/ui/Table/STable';
import { STableCell } from 'components/ui/Table/STableCell';
import { STableHeadCell } from 'components/ui/Table/STableHeader';

const ENTITIES_HEAD_CELLS: STableHeadCell<any>[] = [
  {
    id: 'player',
    numeric: false,
    disablePadding: false,
    label: 'Игрок',
  },
  {
    id: 'role',
    numeric: false,
    disablePadding: false,
    label: 'Роль',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Статус',
  },
  {
    id: 'clan',
    numeric: false,
    disablePadding: false,
    label: 'Клан',
  },
  {
    id: 'computer',
    numeric: false,
    disablePadding: false,
    label: 'Компьютер',
  },
];

const changeRoleValue = (values: IAccountRole[]) =>
  values.length
    ? values
        .reduce((acc: string[], value: IAccountRole) => {
          if (value.clan_id !== '00000000000000000000000000') {
            if (value.clan_name) {
              acc.push(
                `${value.clan_name} - ${rolesTranslateMap[value.role_name]}`
              );
            } else {
              acc.push(`${rolesTranslateMap[value.role_name]}`);
            }
          }

          return acc;
        }, [])
        .join(', ')
    : 'Не выбрана';

const changeClanValue = (values: IClanPlayer[]) =>
  values.length ? values.map((value) => value.clanName).join(', ') : 'Нет';

export const PlayersTable = () => {
  const dispatch = useAppDispatch();
  const { list: players, pending } = useAppSelector(playerSelector);

  useEffect(() => {
    dispatch(getClansThunk());
    dispatch(getAllPlayersThunk());
  }, []);

  const fetchRows = async () => {
    const response: {
      documents: TPlayerTable[];
      total_count: number;
    } = await new Promise((resolve) =>
      resolve({
        total_count: players?.length || 0,
        documents: players || [],
      })
    );

    return {
      rows: response.documents,
      total: response.total_count,
    };
  };

  const navigate = useNavigate();

  const handleRowClick = (_row: any, rowId: Key) => {
    navigate(`/admin-panel/player/${rowId}`);
  };

  if (pending)
    return (
      <div className="h-full w-full absolute top-0 left-0 flex justify-center items-center">
        <Loader />
      </div>
    );

  return (
    <STable
      headCells={ENTITIES_HEAD_CELLS}
      rowIdExtractor={(row) => row?.id}
      fetchRows={fetchRows}
      handleRowClick={handleRowClick}
      classNames={{
        root: 'group !h-[calc(100vh-148px)]',
        row: 'group hover:!bg-ultrablack',
        cell: 'group-hover:!bg-[inherit] !border-b-tpg_light !bg-light',
        body: '[&>tr>td]:h-[93px]',
      }}
      renderRowCells={(row, props) => {
        const roleKey = row.roles.map(({ role_name }) => role_name)?.join(',');

        return (
          <>
            <STableCell
              {...props}
              key={row.name}
              propName="name"
              cellType="text"
            >
              {row.name}
            </STableCell>
            <STableCell
              {...props}
              key={roleKey}
              propName="role"
              cellType="text"
            >
              {changeRoleValue(row.roles)}
            </STableCell>
            <STableCell
              {...props}
              key={row.status}
              propName="status"
              cellType="text"
            >
              {row.status || 'Активный'}
            </STableCell>
            <STableCell
              {...props}
              key={row.clans?.join(',')}
              propName="clans"
              cellType="text"
            >
              {row.clans ? changeClanValue(row.clans) : 'Не указан'}
            </STableCell>
            <STableCell
              {...props}
              key={`${row.computer?.name} - ${row.name}`}
              propName="computer"
              cellType="text"
            >
              {row.computer?.name || 'Не выбрано'}
            </STableCell>
          </>
        );
      }}
    />
  );
};
