import { FC, useState } from 'react';
import { ControllerModal } from 'routes/StreamLaunch/ControllerModal';

import { Button } from 'components/ui';

interface IHostControlPanelProps {
  isPlay: boolean;
  onPause: () => void;
  onResume: () => void;
  onStop: () => void;
}

export const HostControlPanel: FC<IHostControlPanelProps> = ({
  isPlay,
  onPause,
  onResume,
  onStop,
}) => {
  const [isOpenControllerModal, setOpenControllerModal] = useState(false);

  const handleStop = () => {
    setOpenControllerModal(true);
    onStop();
  };

  return (
    <div className="flex flex-row gap-3 pt-6 w-full justify-center">
      <Button
        onClick={isPlay ? onPause : handleStop}
        title={isPlay ? 'Пауза' : 'Завершить'}
        className="w-full max-w-[350px] hover:bg-dark bg-inherit border-2 border-solid border-dark_product"
      />
      <Button
        onClick={isPlay ? handleStop : onResume}
        title={isPlay ? 'Завершить' : 'Возобновить'}
        className="w-full max-w-[350px]"
      />
      {isOpenControllerModal && (
        <ControllerModal onClose={() => setOpenControllerModal(false)} />
      )}
    </div>
  );
};
