import { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalParticipant } from '@livekit/components-react';
import { useRoomListeners } from 'hooks';
import { RoomEvent } from 'livekit-client';
import { TStreamStatus } from 'types';
import { useCountdown } from 'usehooks-ts';

import { notify } from 'utils';

import { useTrackpad } from '../../../../hooks/useTrackpad';
import { HostControlPanel } from '../HostControlPanel';
import StreamDuration from '../StreamDuration';
import StreamStatus from '../StreamStatus';

import { useScreenTracks } from './useScreenTracks';

export const BrowserHostStream: FC = () => {
  const [streamDuration, { startCountdown, stopCountdown, resetCountdown }] =
    useCountdown({
      countStart: 0,
      intervalMs: 1000,
      isIncrement: true,
      countStop: Number.MAX_SAFE_INTEGER,
    });

  const [isPlay, setPlay] = useState(false);

  const navigate = useNavigate();

  const { localParticipant } = useLocalParticipant();

  const { createScreenTracks, stopStream, videoTrack, audioTrack, videoEl } =
    useScreenTracks(localParticipant, {
      onError: () => {
        notify.warning('Выбор потока отменен');
        navigate(-1);
      },
      onSuccess: () => {
        setPlay(true);
        startCountdown();
      },
      screenTrackOptions: {
        audio: {
          autoGainControl: true,
        },
        video: {
          displaySurface: 'browser',
        },
        surfaceSwitching: 'exclude',
      },
    });

  const { pauseTracks, resumeTracks } = useTrackpad([videoTrack, audioTrack]);

  useRoomListeners({
    [RoomEvent.Connected]: createScreenTracks,
  });

  const handlePauseStream = () => {
    pauseTracks();
    stopCountdown();
    setPlay(false);
  };

  const handleResumeStream = () => {
    resumeTracks();
    startCountdown();
    setPlay(true);
  };

  const handleStopStream = () => {
    resetCountdown();
    stopStream();
  };

  const status = useMemo<TStreamStatus>(() => {
    if (isPlay && localParticipant.trackPublications.size) return 'online';

    if (!isPlay && localParticipant.trackPublications.size) return 'pause';

    return 'offline';
  }, [isPlay, localParticipant.trackPublications.size]);

  return (
    <>
      <div className="flex flex-row justify-between">
        <StreamStatus status={status} />
        <StreamDuration milliseconds={streamDuration} />
      </div>

      <div className="flex overflow-hidden aspect-video justify-center relative w-full border-solid border-tpg_light border-[1px] rounded-[10px]">
        <video className="w-full" ref={videoEl} muted />
      </div>
      <div>
        <HostControlPanel
          isPlay={isPlay}
          onPause={handlePauseStream}
          onResume={handleResumeStream}
          onStop={handleStopStream}
        />
      </div>
    </>
  );
};
