import {
  FC,
  MouseEvent,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';

interface CollapseContainerProps {
  hidingBlock: ReactNode;
  classNameContainer?: string;
  classNameButton?: string;
}

export const CollapseContainer: FC<
  PropsWithChildren<CollapseContainerProps>
> = ({ children, hidingBlock, classNameContainer, classNameButton }) => {
  const [isOpen, setOpen] = useState(false);
  const [maxHeight, setMaxHeight] = useState('0px');

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      if (isOpen) {
        setMaxHeight(`${contentRef.current?.scrollHeight}px`);
      } else {
        setMaxHeight('0px');
      }
    }
  }, [isOpen]);

  const handleOpenInfo = (event: MouseEvent) => {
    event.stopPropagation();

    setOpen(!isOpen);
  };

  return (
    <div className="relative">
      <div
        className={cn(
          'border border-solid rounded-t-[10px] border-dark_product',
          classNameContainer
        )}
      >
        {children}
        <div
          ref={contentRef}
          className={cn('opacity-100 transition-all ease-in-out', {
            'overflow-hidden !opacity-0': !isOpen,
          })}
          style={{ maxHeight }}
        >
          {hidingBlock}
        </div>
      </div>
      <div
        className={cn(
          'flex h-[40px] cursor-pointer rounded-b-[10px] border-solid border-b border-x border-x-dark_product border-b-dark_product bg-dark items-center justify-center tpg-c2',
          classNameButton
        )}
        onClick={handleOpenInfo}
      >
        {isOpen ? 'Скрыть' : 'Показать больше'}
      </div>
    </div>
  );
};
