import { FC, useState } from 'react';
import cn from 'classnames';
import { IOption } from 'interfaces';

import { Modal } from 'components/ui';

import { DeleteStreamModal } from './DeleteStreamModal';
import { SaveStreamModal } from './SaveStreamModal';

interface IControllerModalProps {
  onClose: () => void;
}

export const ControllerModal: FC<IControllerModalProps> = ({ onClose }) => {
  const [streamDescription, setStreamDescription] = useState('');
  const [selectedGameTypes, setSelectedGameTypes] = useState<IOption[]>([]);
  const [selectedTags, setSelectedTags] = useState<IOption[]>([]);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

  return (
    <Modal onClose={onClose} isBlurred>
      <div
        className={cn(
          'max-w-[460px] w-[460px] flex flex-col gap-[24px] justify-center border border-solid border-bright rounded-[10px] bg-dark p-[48px]',
          isOpenDeleteModal && 'max-w-[530px] w-[530px]'
        )}
      >
        {isOpenDeleteModal ? (
          <DeleteStreamModal
            onClose={onClose}
            onBack={() => setOpenDeleteModal(false)}
          />
        ) : (
          <SaveStreamModal
            streamDescription={streamDescription}
            selectedGameTypes={selectedGameTypes}
            selectedTags={selectedTags}
            setStreamDescription={setStreamDescription}
            setSelectedGameTypes={setSelectedGameTypes}
            setSelectedTags={setSelectedTags}
            onClose={onClose}
            onReset={() => setOpenDeleteModal(true)}
          />
        )}
      </div>
    </Modal>
  );
};
