import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAccounts } from 'api/account';
import { setClanMember, TSetClanMemberInput } from 'api/clans';
import { register, TRegisterRequest } from 'api/register';
import { errorMessages } from 'constants/errors';
import { asyncActionsNames, reducersNames } from 'constants/reducers';
import { ThunkAPI } from 'interfaces';
import { IPlayer } from 'interfaces/player';

import { createThunk, notify } from 'utils';

import { getClanMembersThunk } from '../clan/actions';

export const registerThunk = createThunk(
  `${reducersNames.PLAYER}/${asyncActionsNames.REGISTER}`,
  register,
  errorMessages.REGISTER_ERROR
);

export const registerWithClanThunk = createAsyncThunk<
  ThunkAPI,
  TRegisterRequest &
    Omit<TSetClanMemberInput, 'memberId'> & { isAddedFromClan?: boolean }
>(
  `${reducersNames.PLAYER}/${asyncActionsNames.REGISTER}`,
  async (request, thunkAPI) => {
    const { email, password, name, clanId, roleId, isAddedFromClan } = request;

    try {
      const data = await register({
        email,
        password,
        name,
      }).then(async (res) => {
        const { data } = res;

        await setClanMember({ clanId, roleId, memberId: data.id });

        return data;
      });

      if (isAddedFromClan) {
        thunkAPI.dispatch(getClanMembersThunk(request.clanId));
      } else {
        thunkAPI.dispatch(getAllPlayersThunk());

        notify.success('Данные сохранены');
      }

      return data;
    } catch (error) {
      notify.error(errorMessages.REGISTER_ERROR);

      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllPlayersThunk = createThunk<IPlayer[]>(
  `${reducersNames.PLAYER}/${asyncActionsNames.GET_ALL_PLAYERS}`,
  getAccounts,
  errorMessages.REGISTER_ERROR
);
