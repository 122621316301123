import { FC, memo } from 'react';
import cn from 'classnames';
import Tooltip from 'rc-tooltip';

import { ElementThemeUnion } from '../../../constants/routes';

import './style.scss';

type CheckboxTypeUnion = 'square' | 'circle';

interface CheckboxProps {
  label?: string | React.ReactNode;
  isChecked: boolean;
  tooltipText?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type?: CheckboxTypeUnion;
  className?: string;
  theme?: ElementThemeUnion;
}

const Checkbox: FC<CheckboxProps> = ({
  label,
  isChecked,
  onChange,
  tooltipText,
  type = 'square',
  className,
  theme = 'light',
}) => {
  const checkboxContent = (
    <label className="tpg-body-one checkbox-container__label">
      <input
        checked={isChecked || false}
        className={cn(`checkbox-container__input__${type}`, {
          'bg-dark': theme === 'dark',
          'bg-ultrablack': theme === 'ultrablack',
          'bg-light': theme === 'light',
        })}
        type="checkbox"
        onChange={onChange}
      />
      {label && <div className="pl-2" />}
      {label}
    </label>
  );

  return (
    <div className={cn('checkbox-container', className)}>
      {tooltipText ? (
        <Tooltip
          overlayClassName="feature-table__row__tooltip"
          placement="top"
          overlay={tooltipText}
        >
          {checkboxContent}
        </Tooltip>
      ) : (
        checkboxContent
      )}
    </div>
  );
};

export default memo(Checkbox);
