import { MouseEvent, useRef, useState } from 'react';
import TableCell from '@mui/material/TableCell';
import { TableCellProps } from '@mui/material/TableCell/TableCell';
import cn from 'classnames';
import { useAppDispatch, useClickOutside } from 'hooks';
import { IMediaFile, ISelectOption } from 'interfaces';
import { TDateRange } from 'types';

import { Checkbox, DatePicker, TextInput, TreeSelect } from 'components/ui';
import Dropzone from 'components/ui/Dropzone';

import { mapActions } from '../../../store';

// ideal: row: R; targetPropName: string;

type EditableTableCellType =
  | 'text'
  | 'selector'
  | 'date'
  | 'media'
  | 'checkbox';

export interface STableCellExtraProps<R> {
  key: string | number;
  row: R;
  handleRowUpdate: (r: R) => void;
  isEditMode: boolean;
  className?: string;
}

export interface STableCellProps<
  R,
  K extends keyof R,
  T extends EditableTableCellType
> extends TableCellProps,
    STableCellExtraProps<R> {
  cellType: T;
  propName: K;
  initialValue?: StateTypeMap[T]['value'];
  selectorOptions?: ISelectOption[];
}

type DateCellState = {
  _type: 'date';
  value?: Date;
};

type TextInputCellState = {
  _type: 'text';
  value: string;
};

type DateRangeCellState = {
  _type: 'date-range';
  value?: TDateRange;
};

type CheckboxCellState = {
  _type: 'checkbox';
  value: boolean;
};

type MediaCellState = {
  _type: 'media';
  value?: IMediaFile[];
};

type SelectorCellState = {
  _type: 'selector';
  value: string;
};

type CellState =
  | DateCellState
  | TextInputCellState
  | DateRangeCellState
  | CheckboxCellState
  | MediaCellState
  | SelectorCellState;

type StateTypeMap = {
  date: DateCellState;
  text: TextInputCellState;
  'date-range': DateRangeCellState;
  checkbox: CheckboxCellState;
  media: MediaCellState;
  selector: SelectorCellState;
};

// type testing helpers
const isDateCellState = (state: CellState): state is DateCellState =>
  state._type === 'date';

const isTextInputCellState = (state: CellState): state is TextInputCellState =>
  state._type === 'text';

const isDateRangeCellState = (state: CellState): state is DateRangeCellState =>
  state._type === 'date-range';

const isCheckboxCellState = (state: CellState): state is CheckboxCellState =>
  state._type === 'checkbox';

const isMediaCellState = (state: CellState): state is MediaCellState =>
  state._type === 'media';

const isSelectorCellState = (state: CellState): state is SelectorCellState =>
  state._type === 'selector';

export function STableCell<
  R,
  K extends keyof R,
  T extends EditableTableCellType
>({
  cellType,
  initialValue,
  selectorOptions,
  className,
  handleRowUpdate,
  isEditMode,
  propName,
  ...props
}: STableCellProps<R, K, T>) {
  const dispatch = useAppDispatch();

  const [isCellEdit, setCellEdit] = useState(false);

  const cellRef = useRef(null);

  useClickOutside<HTMLElement, void>(cellRef, () => {
    // if (value != initialValue) setValue(value); // reset value without handler called
    setCellEdit(false);
  });

  const handleToggleEditMode = (newState?: boolean) =>
    setCellEdit((prevState) =>
      newState === undefined ? !prevState : newState
    );

  const handleSaveChanges = () => {
    handleToggleEditMode(false);
    const updated = { ...props.row } as any;
    updated[propName] = state.value;
    handleRowUpdate(updated as R);
  };

  const [state, setState] = useState({
    _type: cellType,
    value: initialValue,
  } as CellState);

  const handleDeleteMedia = (file: IMediaFile) => {
    dispatch(
      mapActions.setDeleteMode({
        type: 'media',
        data: {
          file,
        },
      })
    );
  };

  const handleAddMedia = (file: IMediaFile) => {
    dispatch(mapActions.addFeatureMedia(file));
  };

  const handlePreviewClick = (list: IMediaFile[]) => {
    dispatch(mapActions.setMediaPreviewMode({ mode: 'features', list }));
  };

  const handleSelectOption = (value: string) => {
    setState({
      ...state,
      value,
    } as CellState);
  };

  const renderCellInEditMode = () => {
    if (isDateRangeCellState(state)) {
      return <>{state.value}</>;
    } else if (isTextInputCellState(state)) {
      return (
        <TextInput
          value={state.value}
          onChange={(v) =>
            setState({
              ...state,
              value: v,
            })
          }
          autoFocus
          onEnter={handleSaveChanges}
          theme="light"
        />
      );
    } else if (isDateCellState(state)) {
      return (
        <DatePicker
          minDate={undefined} // constraint me in future based on field configuration
          maxDate={undefined}
          selected={state.value}
          selectsRange={false}
          onChange={(v) =>
            setState({
              ...state,
              value: v || undefined,
            })
          }
        />
      );
    } else if (isCheckboxCellState(state)) {
      return (
        <Checkbox
          isChecked={state.value}
          onChange={() => setState({ ...state, value: !state.value })}
        />
      );
    } else if (isMediaCellState(state)) {
      return (
        <Dropzone
          onDelete={handleDeleteMedia}
          files={state.value || []}
          onAdd={handleAddMedia}
          onPreviewClick={handlePreviewClick}
          acceptedFormat=".mp4,.jpeg,.jpg,.png,.gif,.avi"
          theme="light"
          className="w-56"
        />
      );
    } else if (isSelectorCellState(state)) {
      return (
        <TreeSelect
          onSelect={handleSelectOption}
          options={selectorOptions || []}
          value={state.value}
          theme={'light'}
        />
      );
    }
    throw new Error('unknown cell type ' + state);
  };

  const minWidth = () => {
    switch (cellType) {
      case 'media':
        return '90px';
      default:
        return '190px';
    }
  };

  const handleDoubleClick = (event: MouseEvent) => {
    event.stopPropagation();

    isEditMode && handleToggleEditMode(true);
  };

  return (
    <TableCell
      ref={cellRef}
      {...props}
      onDoubleClick={handleDoubleClick}
      className={cn('bg-dark', className)}
      style={{ minWidth: minWidth() }}
    >
      {isCellEdit ? renderCellInEditMode() : props.children}
    </TableCell>
  );
}
