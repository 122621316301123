import { useEffect } from 'react';
import { LiveKitRoom } from '@livekit/components-react';
import { EUserRole } from 'constants/profile';
import { useAppDispatch, useAppSelector, useRoles } from 'hooks';
import { accountSelector, authSelector } from 'store';
import { getAccountThunk } from 'store/slices/account/actions';
import { roomSelector } from 'store/slices/room';
import { createRoomThunk } from 'store/slices/room/actions';

import Presence from 'components/Presence';
import { BrowserHostStream } from 'components/StreamPlayerContainer/components/BrowserHostStream';
import Loader from 'components/ui/Loader';

export const StreamLaunchScreen = () => {
  const { username, id: accountId } = useAppSelector(authSelector);
  const { roles } = useAppSelector(accountSelector);

  const { token: currentToken, pending } = useAppSelector(roomSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (accountId) {
      dispatch(getAccountThunk(accountId));
    }
  }, []);

  const clanId = useRoles(
    [EUserRole.ADMIN, EUserRole.MODERATOR, EUserRole.USER],
    roles
  );

  useEffect(() => {
    if (clanId && accountId) {
      dispatch(
        createRoomThunk({
          roomName: `${username}_stream`,
          clanId,
          accountId,
        })
      );
    }
  }, [clanId, accountId]);

  if (pending) return <Loader />;

  if (!clanId) return <>Не хватает прав для содания стрима</>;

  return (
    <LiveKitRoom
      className="flex flex-col gap-[16px] max-w-[650px] m-auto"
      token={currentToken || ''}
      serverUrl={process.env.REACT_APP_LIVEKIT_API}
    >
      <BrowserHostStream />
      <Presence />
    </LiveKitRoom>
  );
};
