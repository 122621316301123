import { useCallback, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { useClickOutside } from 'hooks';
import { ReactComponent as Down } from 'images/newIcons/down.svg';
import { ISelectOption } from 'interfaces';

import { genericMemo } from 'utils';

import {
  ElementPlacementUnion,
  ElementThemeUnion,
} from '../../../constants/routes';

import OptionsList from './OptionsList';

import './style.scss';

interface ISelectProps<T> {
  disabled?: boolean;
  isCreating?: boolean;
  isLoading?: boolean;
  onCreate?: (value: string) => void;
  onSelect: (value: T) => void;
  options: ISelectOption[];
  placeholder?: string;
  searchPlaceholder?: string;
  value: T;
  creationText?: string;
  withEmpty?: boolean;
  withSearch?: boolean;
  isAddMode?: boolean;
  theme?: ElementThemeUnion;
  isExpandable?: boolean;
  menuPlacement?: ElementPlacementUnion;
}

const TreeSelect = <T extends string | number | null>({
  disabled = false,
  isCreating,
  isLoading = false,
  onCreate,
  onSelect,
  options,
  placeholder = 'Выбрать',
  searchPlaceholder = '',
  value,
  creationText,
  withEmpty = true,
  withSearch = false,
  isAddMode = false,
  theme = 'dark',
  isExpandable = false,
  menuPlacement = 'bottom',
}: ISelectProps<T>) => {
  const [isOptionsVisible, setOptionsVisible] = useState(false);

  const selectRef = useRef(null);

  useClickOutside<HTMLUListElement, void>(selectRef, () =>
    setOptionsVisible(false)
  );

  const findOptionById = useCallback(
    (acc: ISelectOption | null, item: ISelectOption): ISelectOption | null => {
      if (item.value === value) return item;

      if (item.children) return item.children.reduce(findOptionById, acc);

      return acc;
    },
    [value, options]
  );

  const optionLabel = useMemo(() => {
    let label = placeholder;

    if (value) {
      label = options.reduce(findOptionById, null)?.label ?? '';
    }

    return label;
  }, [value, options]);

  const handleClick = useCallback(
    (value: T) => {
      onSelect(value);
      setOptionsVisible(false);
    },
    [onSelect]
  );

  const onChangeVisible = () => {
    if (disabled) return;

    setOptionsVisible(!isOptionsVisible);
  };

  const renderSelectorBody = () => {
    return (
      <div
        className={cn('select__body rounded-md', {
          'bg-dark': theme === 'dark',
          'bg-light': theme === 'light',
        })}
        onClick={onChangeVisible}
      >
        <div className="select__body__container">
          <span className="select__body__value tpg-c1 text-tpg_title">
            {optionLabel}
          </span>
        </div>
        <div className="select__body__icon icon-container">
          <Down
            className={cn('chevron', {
              chevron_up: isOptionsVisible,
            })}
          />
        </div>
      </div>
    );
  };

  return (
    <div
      ref={selectRef}
      className={cn('select', {
        select__disabled: disabled,
      })}
    >
      {renderSelectorBody()}
      {isOptionsVisible ? (
        <OptionsList<T>
          handleClick={handleClick}
          isCreating={isCreating}
          isLoading={isLoading}
          onCreate={onCreate}
          options={options}
          placeholder={searchPlaceholder}
          value={value}
          creationText={creationText}
          withEmpty={withEmpty}
          withSearch={withSearch}
          isAddMode={isAddMode}
          theme={theme}
          isExpandable={isExpandable}
          menuPlacement={menuPlacement}
        />
      ) : null}
    </div>
  );
};

export default genericMemo(TreeSelect);
