import { IFilterItem, ISelectOption } from 'interfaces';

export const DATE_FORMAT = 'YYYY-MM-DD';

export const MILLISECONDS = 1000;

export const FEATURE_COLORS = [
  '#d32f2f',
  '#F44870',
  '#f58de4',
  '#7a1fa0',
  '#512da8',
  '#303f9f',
  '#2196f3',
  '#00bcd4',
  '#00796b',
  '#388e3c',
  '#dce775',
  '#f5eb8d',
  '#fbc02d',
  '#f57c00',
  '#ff5722',
  '#a0877e',
  '#5d4037',
  '#455a64',
  '#333333',
  '#ffffff',
];

export enum featureTypes {
  POINT = 'point',
  LINE = 'line',
  POLYGON = 'polygon',
}

export enum collectionTypes {
  POINT = 'Point',
  LINE = 'LineString',
  POLYGON = 'Polygon',
}

export const mapLocale = {
  'AttributionControl.ToggleAttribution': 'Toggle attribution',
  'AttributionControl.MapFeedback': 'Map feedback',
  'FullscreenControl.Enter': 'Enter fullscreen',
  'FullscreenControl.Exit': 'Exit fullscreen',
  'GeolocateControl.FindMyLocation': 'Find my location',
  'GeolocateControl.LocationNotAvailable': 'Location not available',
  'LogoControl.Title': 'Mapbox logo',
  'Map.Title': 'Map',
  'NavigationControl.ResetBearing': 'Reset bearing to north',
  'NavigationControl.ZoomIn': 'Zoom in',
  'NavigationControl.ZoomOut': 'Zoom out',
  'ScrollZoomBlocker.CtrlMessage': 'Use ctrl + scroll to zoom the map',
  'ScrollZoomBlocker.CmdMessage': 'Use ⌘ + scroll to zoom the map',
  'TouchPanBlocker.Message': 'Use two fingers to move the map',
};

export const segmentOptions = ['Пользователи', 'Группы'];

export const ACCESS_TYPE_OPTIONS: ISelectOption[] = [
  { label: 'Приватная', value: 'private' },
  { label: 'Доступно для чтения', value: 'public_readonly' },
  { label: 'Доступно для редактирования', value: 'public_readwrite' },
];

export const ACCESS_MODE_OPTIONS: ISelectOption[] = [
  { label: 'Чтение', value: 'readonly' },
  { label: 'Чтение и редактирование', value: 'readwrite' },
];

export const USERS_FILTER_LIST: IFilterItem[] = [
  {
    title: 'Дата поиска',
    type: 'createdAt',
  },
];

export const relevanceTypes: ISelectOption[] = [
  { label: '1 день', value: '1day' },
  { label: '3 дня', value: '3days' },
  { label: '1 неделя', value: '1week' },
  { label: '1 месяц', value: '1month' },
  { label: '3 месяца', value: '3months' },
  { label: '6 месяцев', value: '6months' },
  { label: '1 год', value: '1year' },
  { label: 'с 24.02.2022', value: 'fromStart' },
];
