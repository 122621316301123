import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { centroid } from '@turf/turf';
import { EUserRole } from 'constants/profile';
import { useAppDispatch, useAppSelector, useRoles } from 'hooks';
import { IOption } from 'interfaces';
import { IClan } from 'interfaces/clan';
import { IPlayer, IUpdatedPlayerData } from 'interfaces/player';
import { mockHistory, NEW_ID, newClanInitialState } from 'mock';
import {
  accountSelector,
  authSelector,
  clanActions,
  clansSelector,
} from 'store';
import {
  createClanThunk,
  deleteClanThunk,
  getClanThunk,
  updateClanThunk,
} from 'store/slices/clan/actions';
import { ID } from 'types';

import { AdminTable } from 'components/AdminTable';
import Loader from 'components/ui/Loader';
import { notify } from 'utils';

import { HistoryInfo } from '../../components/HistoryInfo';

import { BasicInfo } from './BasicInfo';
import { PlayerInfo } from './PlayerInfo';

interface ClanAdminPanelScreenProps {
  isNewClan?: boolean;
}

export type TClanInfo = Omit<IClan, 'computer' | 'access'> & {
  computer: IOption;
  access: IOption[];
};

export const ClanAdminPanelScreen: FC<ClanAdminPanelScreenProps> = ({
  isNewClan,
}) => {
  const dispatch = useAppDispatch();
  const { id: clanId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { role, id: accountId } = useAppSelector(authSelector);
  const { roles } = useAppSelector(accountSelector);
  const { selectedClan, pending } = useAppSelector(clansSelector);
  const [newPlayers, setNewPlayers] = useState<IPlayer[]>([]);
  const moderateClanId = useRoles([EUserRole.MODERATOR], roles);

  useEffect(() => {
    if (role === EUserRole.MODERATOR && moderateClanId) {
      dispatch(getClanThunk(moderateClanId));

      return;
    }

    if (isNewClan) {
      dispatch(clanActions.setNewClan(newClanInitialState));

      return;
    }

    if (clanId && clanId !== NEW_ID) {
      dispatch(getClanThunk(clanId));

      return;
    }
  }, [clanId, isNewClan, role, moderateClanId]);

  useEffect(() => {
    if (state?.features) {
      const polygonСenter =
        state?.features && centroid(state.features).geometry.coordinates;

      dispatch(
        clanActions.modifySelected({
          coordinates: state?.features.geometry.coordinates,
          longitude: polygonСenter[0],
          latitude: polygonСenter[1],
        })
      );
    }
  }, [state]);

  const handleAddNewPlayer = (value: IPlayer) => {
    setNewPlayers((prev) => [...prev, value]);
  };

  const handleRemovePlayer = (id: ID) => {
    setNewPlayers((prev) => prev.filter((player) => player.id !== id));
  };

  const handleUpdatePlayer = (value: IUpdatedPlayerData) => {
    setNewPlayers((prev) =>
      prev.map((player) =>
        player.id === value.id ? { ...player, ...value } : player
      )
    );
  };

  const handleChangeClanInfo = (value: Partial<IClan>) => {
    dispatch(clanActions.modifySelected(value));
  };

  const handleUpdateClan = () => {
    if (selectedClan) {
      isNewClan
        ? dispatch(
            createClanThunk({
              clan: { ...selectedClan, admin_id: String(accountId) },
              players: newPlayers,
            })
          )
        : dispatch(updateClanThunk(selectedClan));
    }

    notify.success('Данные сохранены');

    navigate(-1);
  };

  const handleRemoveClan = () => {
    if (clanId) {
      dispatch(deleteClanThunk(clanId));

      notify.success('Клан удалён');

      navigate(-1);
    }
  };

  if (pending || !selectedClan)
    return (
      <div className="h-full w-full absolute top-0 left-0 flex justify-center items-center">
        <Loader />
      </div>
    );

  return (
    <AdminTable
      title={isNewClan ? 'Новый клан' : selectedClan.name}
      historyTitle="История стримов"
      listTitle="Список игроков"
      isShowFooterController={role === EUserRole.ADMIN}
      isNewData={isNewClan}
      info={
        <BasicInfo
          clanInfo={selectedClan}
          isNewClan={isNewClan}
          onChange={handleChangeClanInfo}
        />
      }
      history={<HistoryInfo history={mockHistory} />}
      list={
        <PlayerInfo
          selectedClan={selectedClan}
          isNewClan={isNewClan}
          newPlayers={newPlayers}
          onAddPlayer={handleAddNewPlayer}
          onRemovePlayer={handleRemovePlayer}
          onUpdatePlayer={handleUpdatePlayer}
        />
      }
      onSave={handleUpdateClan}
      onRemove={handleRemoveClan}
    />
  );
};
