import { useMemo } from 'react';
import { LocalTrack } from 'livekit-client';

const isNotNullTracks = (
  tracks: (LocalTrack | undefined)[]
): tracks is LocalTrack[] => {
  return tracks.every(Boolean);
};

export const useTrackpad = (tracks: (LocalTrack | undefined)[]) => {
  const callbacks = useMemo(
    () => ({
      resumeTracks() {
        if (isNotNullTracks(tracks))
          tracks.forEach((track) => track.resumeUpstream());
      },

      stopTracks() {
        if (isNotNullTracks(tracks)) tracks.forEach((track) => track.stop());
      },

      pauseTracks() {
        if (isNotNullTracks(tracks))
          tracks.forEach((track) => track.pauseUpstream());
      },
    }),
    [tracks]
  );

  return callbacks;
};
