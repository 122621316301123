import { FC } from 'react';
import { ReactComponent as PlusIcon } from 'images/newIcons/plus.svg';

interface AddButtonProps {
  label: string;
  onClick: () => void;
}

export const AddButton: FC<AddButtonProps> = ({ label, onClick }) => {
  const handleClickButton = () => {
    onClick();
  };

  return (
    <div
      className="h-[20px] gap-[4px] flex flex-row group cursor-pointer justify-center min-w-[140px] hover:text-bright_product transition"
      onClick={handleClickButton}
    >
      <span className="tpg-c2">{label}</span>
      <PlusIcon className="group-hover:[&>g>path]:fill-bright_product w-[20px] h-[20px] [&>g>path]:transition" />
    </div>
  );
};
