import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { reducersNames } from 'constants/reducers';

interface IStreamState {
  searchQuery: string;
}

const initialState: IStreamState = {
  searchQuery: '',
};

const streamSlice = createSlice({
  name: reducersNames.STREAM,
  initialState,
  reducers: {
    setQuerySearch(state, action: PayloadAction<string>) {
      state.searchQuery = action.payload;
    },
    clearQuerySearch(state) {
      state.searchQuery = '';
    },
    resetStream: () => initialState,
  },
});

export const { actions: streamActions, reducer: streamReducer } = streamSlice;

export * from './selectors';
