import { FC, useMemo } from 'react';

import { getDurationString } from 'utils';

interface IStreamDurationProps {
  milliseconds: number;
}

const StreamDuration: FC<IStreamDurationProps> = ({
  milliseconds: milliseconds,
}) => {
  const timeString = useMemo(
    () => getDurationString(milliseconds),
    [milliseconds]
  );

  return <span className="tpg-b1 text-error">{timeString}</span>;
};

export default StreamDuration;
