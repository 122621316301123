import {
  clearLStorageMany,
  getLStorage,
  setLStorage,
} from './helpers/localStorage';

export const setToken = (token: string) => setLStorage(['token', token]);

export const setTileToken = (token: string) =>
  setLStorage(['tile_token', token]);

export const getToken = () => getLStorage('token');

export const getTileToken = () => getLStorage('tile_token');

export const removeToken = () =>
  clearLStorageMany('access_token', 'tile_token');
