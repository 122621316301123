import axios, {
  AxiosError,
  AxiosRequestHeaders,
  AxiosResponse,
  HttpStatusCode,
} from 'axios';

import { getToken } from 'utils';
import { getLStorage, setLStorage } from 'utils/helpers/localStorage';

const { REACT_APP_API_URL, NODE_ENV, REACT_APP_DEX_SECRET } = process.env;

export const __DEV__ = NODE_ENV === 'development';

export const baseURL = REACT_APP_API_URL;

const instance = axios.create({
  baseURL,
});

const responseSuccessHandler = (response: AxiosResponse) => {
  return response;
};

const responseErrorHandler = async (error: AxiosError) => {
  const original = error?.config;

  if (original) {
    if (error.response?.status === HttpStatusCode.Unauthorized) {
      const { data } = await axios.post(`${baseURL}/token`, {
        refresh_token: getLStorage('refresh_token'),
        client_secret: REACT_APP_DEX_SECRET,
      });

      setLStorage(['token', data.token]);
      setLStorage(['refresh_token', data.refresh_token]);

      original.headers['Authorization'] = `Bearer ${data.token}`;

      instance.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${data.token}`;

      return instance(original);
    }
  }

  return error;
};

instance.interceptors.request.use((config) => {
  const authToken = getToken();

  if (authToken) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${authToken}`,
    } as AxiosRequestHeaders;
  }

  return config;
});

instance.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  (error) => responseErrorHandler(error)
);

export const createLoadSource = () => axios.CancelToken.source();

export default instance;
