import { FC, memo } from 'react';
import { TStreamStatus } from 'types';

import { statusTitle } from './constant';

interface IStreamStatusProps {
  status: TStreamStatus;
}

const StreamStatus: FC<IStreamStatusProps> = ({ status }) => {
  return <span className="tpg-h4">{statusTitle[status]}</span>;
};

export default memo(StreamStatus);
