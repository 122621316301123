import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { EUserRole } from 'constants/profile';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ReactComponent as FullSize } from 'images/newIcons/fullSize.svg';
import { IRoom } from 'interfaces';
import { authSelector } from 'store';
import { roomSelector } from 'store/slices/room';
import { getClanRoomsThunk } from 'store/slices/room/actions';
import { ID } from 'types/common';

import { Card } from 'components/Card';

import { CardList } from './CardList';

interface ClanCardProps {
  clanId: ID;
  onClose: () => void;
}

export const ClanCard: FC<ClanCardProps> = ({ clanId, onClose }) => {
  const dispatch = useAppDispatch();
  const { role } = useAppSelector(authSelector);
  const { rooms } = useAppSelector(roomSelector);
  const [card, setCard] = useState<IRoom>();
  const [selectedStreamId, setSelectedStreamId] = useState<string>();

  const clanRooms = rooms.get(String(clanId)) || [];

  useEffect(() => {
    if (clanRooms.length) {
      setCard(clanRooms[0]);
      setSelectedStreamId(clanRooms[0].livekitName);
    }

    if (!clanRooms.length) {
      dispatch(getClanRoomsThunk(String(clanId)));
    }
  }, [clanRooms.length]);

  const navigate = useNavigate();

  const handleSelectStream = (streamId: string) => {
    const selectedStream = clanRooms.find(
      ({ livekitName }) => streamId === livekitName
    );

    if (selectedStream) {
      setSelectedStreamId(streamId);
      setCard(selectedStream);
    }
  };

  const handleOpenCard = () => {
    navigate(`/admin-panel/clan/${clanId}`);
  };

  return (
    <div
      className="z-[1] w-full h-full absolute flex items-center justify-center"
      onClick={onClose}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={cn(
          'flex flex-col min-h-fit justify-between bg-ultrablack m-full min-w-[660px] max-w-[660px] h-md:!min-w-[575px] h-md:!max-w-[575px] rounded-[10px] border border-solid border-tpg_light'
        )}
      >
        {!!clanRooms.length && (
          <div className="flex flex-col gap-[8px] p-[16px]">
            {card && (
              <div>
                <Card
                  cardId={String(card.livekitName)}
                  src=""
                  tags={card.tags}
                  className="first:h-[370px] h-md:first:h-[280px]"
                  type="stream"
                  clanId={clanId}
                />
              </div>
            )}
            {selectedStreamId && (
              <CardList
                rooms={clanRooms}
                clanId={clanId}
                selectedStreamId={selectedStreamId}
                onSelect={handleSelectStream}
              />
            )}
          </div>
        )}
        <div className="flex flex-col">
          <div
            className={cn(
              'flex flex-row border-b border-solid border-tpg_light px-[12px] tpg-b2',
              { 'border-t': !!clanRooms.length }
            )}
          >
            <div className="min-w-[180px] border-r border-solid border-tpg_light py-[4px]">
              <span className="text-tpg_base">В эфире</span>
            </div>
            <div className="flex w-full py-[4px]">
              <span className="ml-auto">0</span>
            </div>
          </div>
          <div className="flex flex-row px-[12px] tpg-b2">
            <div className="min-w-[180px] border-r border-solid border-tpg_light py-[4px]">
              <span className="text-tpg_base">Записи</span>
            </div>
            <div className="flex w-full py-[4px]">
              <span className="ml-auto">0</span>
            </div>
          </div>
          {role === EUserRole.ADMIN && (
            <div
              className="group bg-light w-full flex flex-row items-center justify-center p-[9px] cursor-pointer rounded-b-[10px] border-t border-solid border-tpg_light hover:text-bright_product transition"
              onClick={handleOpenCard}
            >
              <FullSize className="group-hover:[&>g>path]:fill-bright_product [&>g>path]:transition" />
              <span className="tpg-c2">Открыть карточку</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
