import { createSlice } from '@reduxjs/toolkit';
import { EUserRole } from 'constants/profile';
import { reducersNames } from 'constants/reducers';
import { IAuthState } from 'interfaces';
import { rolesValuesMap } from 'types/player';

import { getToken } from 'utils';
import {
  clearLStorageMany,
  getLStorage,
  setLStorageMany,
} from 'utils/helpers/localStorage';

import { getAccountThunk } from '../account/actions';

import { loggedInThunk, loginThunk } from './actions';

const initialState: IAuthState = {
  pending: false,
  error: null,
  //the temporary initial state will later change depending on the data
  role: EUserRole.ADMIN,
  id: getLStorage('account_id'),
  accessToken: getToken(),
  refreshToken: getLStorage('refresh_token'),
  username: getLStorage('user_name'), //the temporary initial state will later change depending on the data
  privileges: {
    role: EUserRole.ADMIN,
  },
  redirectUrl: null,
};

const authSlice = createSlice({
  name: reducersNames.AUTH,
  initialState,
  reducers: {
    logout(state) {
      clearLStorageMany(...Object.keys(state));
      state.accessToken = null;
      state.privileges = null;
      state.username = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginThunk.fulfilled, (state, { payload: { redirect_url } }) => {
        if (redirect_url) {
          state.redirectUrl = redirect_url;
          window.location.replace(redirect_url);
        }
        state.pending = false;
      })
      .addCase(loginThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(loginThunk.rejected, (state) => {
        state.pending = false;
        state.error = 'Ошибка сети';
      })
      .addCase(loggedInThunk.fulfilled, (state, action) => {
        state.accessToken = action.payload.token;
        state.username = action.payload.user_name;
        setLStorageMany(action.payload);
        state.pending = false;
        state.redirectUrl = null;
      })
      .addCase(getAccountThunk.fulfilled, (state, action) => {
        if (action.payload.id !== rolesValuesMap.root) {
          state.role = EUserRole.ADMIN;
          state.privileges = { role: EUserRole.ADMIN };
        }
      });
  },
});

export const { actions: authActions, reducer: authReducer } = authSlice;

export * from './actions';
export * from './selectors';
