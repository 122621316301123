import { FC, useState } from 'react';
import { useAppDispatch } from 'hooks';
import { IOption } from 'interfaces';
import { roleOptions } from 'mock';
import { setClanMemberThunk } from 'store/slices/clan/actions';

import { Button, Select } from 'components/ui';

interface AddAccessCardProps {
  accountId: string;
  clanOptions: IOption[];
  onClose: () => void;
}

export const AddAccessCard: FC<AddAccessCardProps> = ({
  accountId,
  clanOptions,
  onClose,
}) => {
  const [selectedRole, setSelectedRole] = useState<IOption>({
    value: '',
    label: '',
  });

  const [selectedClan, setSelectedClan] = useState<IOption>({
    value: '',
    label: '',
  });

  const dispatch = useAppDispatch();

  const handleSelectClans = (value: IOption | IOption[]) => {
    if (!Array.isArray(value)) {
      setSelectedClan(value);
    }
  };

  const handleSelectRole = (value: IOption | IOption[]) => {
    if (!Array.isArray(value)) {
      setSelectedRole(value);
    }
  };

  const handleClose = () => {
    onClose();

    setSelectedRole({
      value: '',
      label: '',
    });

    setSelectedClan({
      value: '',
      label: '',
    });
  };

  const handleSave = () => {
    if (selectedClan.value && selectedRole.value) {
      dispatch(
        setClanMemberThunk({
          clanId: selectedClan.value,
          memberId: accountId,
          roleId: selectedRole.value,
          isAddedFromAccount: true,
        })
      );
    }

    handleClose();
  };

  return (
    <div className="flex flex-col gap-[16px]">
      <div className="border border-solid rounded-[10px] border-dark_product">
        <Select
          value={selectedClan}
          options={clanOptions}
          onSelect={handleSelectClans}
          placeholder="Клан"
          className="pl-[16px] rounded-t-[10px] border-b border-solid border-b-dark_product"
        />
        <Select
          value={selectedRole}
          options={roleOptions}
          onSelect={handleSelectRole}
          placeholder="Роль"
          className="pl-[16px] rounded-b-[10px] rounded-t-[10px]"
        />
      </div>
      <div className="flex flex-row gap-[12px]">
        <Button
          onClick={handleClose}
          title="Отмена"
          className="w-full hover:bg-dark_product bg-inherit border-2 border-solid border-dark_product"
        />
        <Button onClick={handleSave} title="Добавить" className="w-full" />
      </div>
    </div>
  );
};
