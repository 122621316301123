import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { authRoutes, navigation } from 'constants/routes';
import { useAppDispatch, useAppSelector } from 'hooks';
import { authSelector, loggedInThunk } from 'store';
import { getAccountThunk } from 'store/slices/account/actions';

import { Button } from 'components/ui';
import Loader from 'components/ui/Loader';

const { REACT_APP_DEX_SECRET } = process.env;

export const LoggedInScreen = () => {
  const { search } = useLocation();

  const { error, pending, accessToken, role, id, refreshToken } =
    useAppSelector(authSelector);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const loggedInData = useMemo(() => {
    const searchParams = new URLSearchParams(search);

    if (searchParams.size === 0) {
      return null;
    }

    return {
      code: searchParams.get('code'),
      state: searchParams.get('state'),
    };
  }, [search]);

  useEffect(() => {
    const client_secret = REACT_APP_DEX_SECRET;

    if (loggedInData && client_secret)
      dispatch(
        loggedInThunk({
          ...loggedInData,
          client_secret,
        })
      );

    if (id) dispatch(getAccountThunk(id));
  }, [loggedInData]);

  useEffect(() => {
    if (accessToken) navigate(navigation[role]); // temporary redirect path
  }, [accessToken]);

  return (
    <div>
      <div>
        {pending && <Loader />}
        {error && (
          <>
            <h2>{error}</h2>
            <Button
              onClick={() => navigate(authRoutes.SIGN_IN)}
              title="Попробовать снова"
            />
          </>
        )}
      </div>
    </div>
  );
};
