import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { RecordSection } from 'components/RecordSection';

export const RecordsScreen: FC = () => {
  const {
    state: { clanId },
  } = useLocation();

  return (
    <div className="flex flex-col gap-[16px]">
      {clanId && <RecordSection clanId={clanId} />}
      {!clanId && <h2>Нет данных о клане</h2>}
    </div>
  );
};
