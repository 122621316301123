import { createSlice } from '@reduxjs/toolkit';
import { reducersNames } from 'constants/reducers';
import { IAccountState, TSliceWithPromiseFlags } from 'interfaces';

import { notify } from 'utils';
import { getLStorage } from 'utils/helpers/localStorage';

import {
  getAccountThunk,
  updateAccountEmailThunk,
  updateAccountPasswordThunk,
  updateAccountRoleThunk,
  updateAccountThunk,
} from './actions';

const initialState: TSliceWithPromiseFlags<IAccountState> = {
  pending: false,
  error: null,
  email: '',
  external_id: '',
  id: getLStorage('account_id') || '',
  name: '',
  roles: [],
};

const accountSlice = createSlice({
  name: reducersNames.AUTH,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAccountThunk.fulfilled, (state, { payload }) => {
        state.pending = false;
        state.error = null;
        state.roles = payload.roles;
        state.id = payload.id;
        state.name = payload.name;
      })
      .addCase(getAccountThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(getAccountThunk.rejected, (state) => {
        state.pending = false;
        state.error = 'Ошибка загрузки';
      })
      .addCase(updateAccountThunk.fulfilled, (state) => {
        state.pending = false;
        state.error = null;

        notify.success('Данные игрока обновлены');
      })
      .addCase(updateAccountThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(updateAccountThunk.rejected, (state) => {
        state.pending = false;
        state.error = 'Ошибка загрузки';
      })
      .addCase(updateAccountPasswordThunk.fulfilled, (state) => {
        state.pending = false;
        state.error = null;

        notify.success('Пароль игрока изменен');
      })
      .addCase(updateAccountPasswordThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(updateAccountPasswordThunk.rejected, (state) => {
        state.pending = false;
        state.error = 'Ошибка загрузки';
      })
      .addCase(updateAccountEmailThunk.fulfilled, (state) => {
        state.pending = false;
        state.error = null;

        notify.success('Логин игрока изменен');
      })
      .addCase(updateAccountEmailThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(updateAccountEmailThunk.rejected, (state) => {
        state.pending = false;
        state.error = 'Ошибка загрузки';
      })
      .addCase(updateAccountRoleThunk.fulfilled, (state) => {
        state.pending = false;
        state.error = null;

        notify.success('Роль игрока изменена');
      })
      .addCase(updateAccountRoleThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(updateAccountRoleThunk.rejected, (state) => {
        state.pending = false;
        state.error = 'Ошибка загрузки';
      });
  },
});

export const { actions: accountActions, reducer: accountReducer } =
  accountSlice;

export * from './selectors';
