import { CSSProperties, FC, ReactNode } from 'react';
import cn from 'classnames';
import { useEscapeDown } from 'hooks/useEscapeDown';

import './style.scss';

interface IModalProps {
  children: ReactNode;
  title?: string;
  keyboard?: boolean;
  width?: string | number;
  onClose: () => void;
  className?: string;
  closeOnOutsideClick?: boolean;
  style?: CSSProperties;
  isBlurred?: boolean;
}

const Modal: FC<IModalProps> = ({
  children,
  className,
  onClose,
  title,
  width = 'max-content',
  keyboard = false,
  closeOnOutsideClick = true,
  style,
  isBlurred = false,
}) => {
  const modalStyle: CSSProperties = {
    pointerEvents: 'all',
    maxWidth: width,
    ...style,
  };

  useEscapeDown(onClose, keyboard);

  return (
    <div
      className={cn('modal', {
        modal__blurred: isBlurred,
      })}
      onClick={() => closeOnOutsideClick && onClose()}
      style={{ pointerEvents: closeOnOutsideClick ? 'unset' : 'none' }}
    >
      <div
        className={cn(`modal__body ${className || ''}`)}
        style={modalStyle}
        onClick={(e) => e.stopPropagation()}
      >
        {title && <div>{title}</div>}
        {children}
      </div>
    </div>
  );
};

export default Modal;
