import { useState } from 'react';
import { ID } from 'types/common';

import { ClanCard } from './components/ClanCard';
import { MapComponent } from './components/MapComponent';

export const MapScreen = () => {
  const [selectedClanId, setSelectedClanId] = useState<ID>('');

  const handleCloseCard = () => {
    setSelectedClanId('');
  };

  const handleSelectClanId = (id: ID) => {
    setSelectedClanId(id);
  };

  return (
    <div className="relative flex flex-col w-[calc(100%+48px)] h-[calc(100%+48px)] m-[-24px]">
      {selectedClanId && (
        <ClanCard clanId={selectedClanId} onClose={handleCloseCard} />
      )}
      <MapComponent
        selectedMarker={selectedClanId}
        setSelectedMarker={handleSelectClanId}
      />
    </div>
  );
};
