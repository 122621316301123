import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createClan,
  deleteClan,
  deleteClanMember,
  getClan,
  getClanMembers,
  getClans,
  setClanMember,
  TSetClanMemberInput,
  updateClan,
} from 'api/clans';
import { ICreateClanThunkData } from 'api/type/clan';
import { AxiosResponse } from 'axios';
import { errorMessages } from 'constants/errors';
import { asyncActionsNames, reducersNames } from 'constants/reducers';
import { ThunkAPI } from 'interfaces';
import { IClan } from 'interfaces/clan';
import { IPlayer } from 'interfaces/player';
import { ID } from 'types';
import { TClanResponse } from 'types/clan';

import { createThunk, notify } from 'utils';

import { getAllPlayersThunk, registerWithClanThunk } from '../player/actions';

export const getClansThunk = createThunk<TClanResponse[]>(
  `${reducersNames.CLAN}/${asyncActionsNames.GET_CLANS}`,
  getClans
);

export const getClanThunk = createThunk<TClanResponse, string>(
  `${reducersNames.CLAN}/${asyncActionsNames.GET_CLAN}`,
  getClan,
  errorMessages.GET_CLAN
);

export const createClanThunk = createAsyncThunk<ThunkAPI, ICreateClanThunkData>(
  `${reducersNames.CLAN}/${asyncActionsNames.CREATE_CLAN}`,
  async (request, thunkAPI) => {
    const { clan, players } = request;

    const { coordinates, admin_id, name, work_area } = clan;

    try {
      const clanData = await createClan({
        coordinates,
        admin_id,
        name,
        work_area,
      }).then(async (res) => {
        const { data } = res;

        await Promise.all(
          players.map((player) =>
            thunkAPI.dispatch(
              registerWithClanThunk({
                clanId: data.id,
                password: player.password,
                name: player.name,
                roleId: player.role,
                email: player.email,
                isAddedFromClan: true,
              })
            )
          )
        );

        return data;
      });

      return clanData;
    } catch (error) {
      notify.error(errorMessages.CREATE_CLAN);

      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateClanThunk = createThunk<IClan, IClan>(
  `${reducersNames.CLAN}/${asyncActionsNames.UPDATE_CLAN}`,
  updateClan,
  errorMessages.UPDATE_CLAN
);

export const deleteClanThunk = createThunk(
  `${reducersNames.CLAN}/${asyncActionsNames.DELETE_CLAN}`,
  deleteClan,
  errorMessages.DELETE_CLAN
);

export const setClanMemberThunk = createAsyncThunk<
  AxiosResponse<any, any>,
  TSetClanMemberInput
>(
  `${reducersNames.CLAN}/${asyncActionsNames.SET_CLAN_MEMBER}`,
  async (request, thunkAPI) => {
    const { clanId, memberId, roleId, isAddedFromAccount } = request;

    try {
      const data = await setClanMember({
        clanId,
        memberId,
        roleId,
      });

      if (isAddedFromAccount) {
        thunkAPI.dispatch(getAllPlayersThunk());

        notify.success('Доступ добавлен');
      }

      return data;
    } catch (error) {
      notify.error(errorMessages.REGISTER_ERROR);

      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteClanMemberThunk = createAsyncThunk<
  AxiosResponse<any, any>,
  Omit<TSetClanMemberInput, 'roleId'> & { isAddedFromAccount?: boolean }
>(
  `${reducersNames.CLAN}/${asyncActionsNames.DELETE_CLAN_MEMBER}`,
  async (request, thunkAPI) => {
    const { clanId, memberId, isAddedFromAccount } = request;

    try {
      const data = await deleteClanMember({
        clanId,
        memberId,
      });

      if (isAddedFromAccount) {
        thunkAPI.dispatch(getAllPlayersThunk());

        notify.success('Доступ удалён');
      }

      return data;
    } catch (error) {
      notify.error(errorMessages.DELETE_CLAN_MEMBER);

      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getClanMembersThunk = createThunk<IPlayer[], ID>(
  `${reducersNames.CLAN}/${asyncActionsNames.GET_CLAN_MEMBERS}`,
  getClanMembers,
  errorMessages.GET_CLAN
);
