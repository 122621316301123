import { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { format } from 'date-fns';
import { useAppDispatch, useAppSelector } from 'hooks';
import { streamActions, streamSelector } from 'store';
import { recordsSelector } from 'store/slices/records';
import { getClanRecordsThunk } from 'store/slices/records/actions';
import { TDateRange } from 'types';

import { Card } from 'components/Card';
import { DateTag } from 'components/FilterSection/DateTag';
import { DatePickerDropdown } from 'components/ui/DatePickerDropdown/DatePickerDropdown';
import { Tag } from 'components/ui/Tag';

interface IRecordSectionProps {
  clanId: string;
}

export const RecordSection: FC<IRecordSectionProps> = ({ clanId }) => {
  const dispatch = useAppDispatch();
  const { searchQuery } = useAppSelector(streamSelector);
  const { records } = useAppSelector(recordsSelector);
  const clanRecords = records.get(clanId) || [];
  const [streamDate, setStreamDate] = useState<(string | null)[]>([]);

  const handleDatePicker = (dates: TDateRange) => {
    const formatedDates = new Set(
      dates.map((date) => date && format(date, 'dd.MM.yyyy'))
    );

    setStreamDate(Array.from(formatedDates));
  };

  useEffect(() => {
    dispatch(getClanRecordsThunk(clanId));
  }, [clanId, streamDate]);

  if (!clanRecords.length) return null;

  return (
    <div className="flex flex-col gap-[16px]">
      <div className="flex flex-row justify-between items-center">
        <span className="tpg-h4">{`${clanRecords.length} в записи`}</span>
        <DatePickerDropdown
          // where is a valid value
          value={!streamDate.length ? [null, null] : undefined}
          onValueChange={handleDatePicker}
          className="mt-5 right-0"
          iconClassName={cn(
            'cursor-pointer',
            streamDate.length && '[&>path]:fill-bright_product'
          )}
        />
      </div>
      {(!!streamDate.length || searchQuery) && (
        <div className="flex flex-row gap-[8px]">
          {searchQuery && (
            <Tag
              label="Поисковый запрос"
              onRemove={() => dispatch(streamActions.clearQuerySearch())}
              closeIcon
            />
          )}
          {!!streamDate.length && (
            <DateTag date={streamDate} setDate={setStreamDate} />
          )}
        </div>
      )}
      <div className="grid grid-cols-2 gap-[16px] w-full">
        {clanRecords.map(({ id }) => (
          <Card
            type="record"
            key={id}
            cardId={id}
            src={''}
            tags={[]}
            clanId={clanId}
          />
        ))}
      </div>
    </div>
  );
};
