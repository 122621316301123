import { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { roomSelector } from 'store/slices/room';
import { getClanRoomsThunk } from 'store/slices/room/actions';

import { Card } from 'components/Card';

interface IOnlineSectionProps {
  clanId: string;
}

export const OnlineSection: FC<IOnlineSectionProps> = ({ clanId }) => {
  const { rooms } = useAppSelector(roomSelector);
  const clanRooms = rooms.get(clanId) || [];
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getClanRoomsThunk(clanId));
  }, [clanId]);

  return (
    <div className="flex flex-col gap-[16px]">
      <span className="tpg-h4">{`${clanRooms.length} в эфире`}</span>
      <div className="grid grid-cols-2 gap-[16px] w-full">
        {clanRooms.map(({ tags, livekitName }) => (
          <Card
            key={livekitName}
            cardId={String(livekitName)}
            src=""
            tags={tags}
            type="stream"
            clanId={clanId}
          />
        ))}
      </div>
    </div>
  );
};
