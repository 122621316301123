import { FC, useEffect, useMemo, useRef, useState } from 'react';
import Map, {
  Layer,
  MapLayerMouseEvent,
  MapRef,
  Marker,
  Source,
} from 'react-map-gl';
import { useNavigate } from 'react-router-dom';
import { centroid } from '@turf/turf';
import cn from 'classnames';
import { EUserRole } from 'constants/profile';
import { Feature, GeoJsonProperties, Geometry } from 'geojson';
import { useAppDispatch, useAppSelector } from 'hooks';
import { IDrawEvent } from 'interfaces';
import { authSelector, clansSelector } from 'store';
import { getClansThunk, updateClanThunk } from 'store/slices/clan/actions';
import { ID } from 'types/common';
import { useDebouncedCallback } from 'use-debounce';

import { ControlPanel } from '../ControlPanel';

import { DrawControl, mapboxDraw } from './DrawControl';

import './style.scss';
import 'mapbox-gl/dist/mapbox-gl.css';

const { REACT_APP_MAPBOX_ACCESS_TOKEN } = process.env;

interface MapComponentProps {
  selectedMarker?: ID;
  setSelectedMarker?: (value: ID) => void;
}

export const MapComponent: FC<MapComponentProps> = ({
  selectedMarker,
  setSelectedMarker,
}) => {
  const [isMapLoaded, setMapLoaded] = useState(false);

  const mapRef = useRef<MapRef>(null);
  const selectedClanId = useRef<ID>();

  const { role } = useAppSelector(authSelector);
  const { clans, pending } = useAppSelector(clansSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getClansThunk());
  }, []);

  const navigate = useNavigate();

  const polygons: Feature<Geometry, GeoJsonProperties>[] = useMemo(
    () =>
      clans.map(({ id, coordinates }) => ({
        id,
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Polygon',
          coordinates,
        },
      })),
    [clans]
  );

  useEffect(() => {
    if (polygons.length && isMapLoaded && role === EUserRole.ADMIN) {
      polygons.forEach((polygon) => {
        mapboxDraw?.add(polygon);
      });
    }
  }, [polygons, isMapLoaded, role]);

  const handleMapLoad = () => {
    setMapLoaded(true);
  };

  const handleChangeViewport = useDebouncedCallback((value: string) => {
    if (!clans.length) return;

    const foundClan = clans.find(
      ({ name }) => name.toLowerCase() === value.toLowerCase()
    );

    if (foundClan) {
      const map = mapRef?.current?.getMap();

      map?.flyTo({
        center: [foundClan.longitude, foundClan.latitude],
        zoom: 10,
      });
    }
  }, 200);

  const handleCreatePolygon = ({ features }: IDrawEvent) => {
    if (features[0]) {
      navigate('/admin-panel/clan/new', {
        state: { features: features[0] },
      });
    }
  };

  const handleSelectPolygon = (event: MapLayerMouseEvent) => {
    const map = mapRef?.current?.getMap();

    const selectedPolygon = map?.queryRenderedFeatures(event.point);

    const featureId =
      selectedPolygon?.length && selectedPolygon[0]?.properties?.id;

    if (featureId) {
      selectedClanId.current = featureId;
    }
  };

  const handleChangePolygon = () => {
    const currentFeatures = mapboxDraw.getAll().features;

    const foundClan = clans.find((clan) => clan.id === selectedClanId.current);

    const foundFeature: any = currentFeatures.find(
      (feature) => feature.id === selectedClanId.current
    );

    if (foundFeature && foundClan) {
      const polygonСenter = centroid(foundFeature).geometry.coordinates;

      const newClan = {
        ...foundClan,
        coordinates: foundFeature.geometry?.coordinates,
        longitude: polygonСenter[0],
        latitude: polygonСenter[1],
      };

      dispatch(updateClanThunk(newClan));
    }
  };

  return (
    <Map
      ref={mapRef}
      mapboxAccessToken={REACT_APP_MAPBOX_ACCESS_TOKEN}
      minZoom={1.5}
      initialViewState={{
        longitude: 60,
        latitude: 15,
        zoom: 1.5,
      }}
      onLoad={handleMapLoad}
    >
      <ControlPanel onChange={handleChangeViewport} />
      {clans.map(({ id, longitude, latitude, preview_src, name }) => (
        <Marker key={id} longitude={longitude} latitude={latitude}>
          <div
            className="flex flex-col items-center gap-[8px]"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedMarker?.(id);
            }}
          >
            {preview_src && (
              <div
                className={cn(
                  'w-[42px] h-[42px] border-solid border-[2px] border-tpg_title rounded-[50%] cursor-pointer',
                  { '!border-bright_product': selectedMarker === id }
                )}
              >
                <img
                  className="rounded-[50%] w-full h-full object-cover"
                  src={preview_src}
                  alt="clan"
                />
              </div>
            )}
            <span className="title">{name}</span>
          </div>
        </Marker>
      ))}
      <Source
        id="google"
        key="google"
        type="raster"
        tiles={['http://mt0.google.com/vt/lyrs=y&hl=ru&x={x}&y={y}&z={z}']}
      >
        <Layer id="google" type="raster" source="google" />
      </Source>
      {role === EUserRole.ADMIN && (
        <Source
          id="polygon-source"
          key="polygon-source"
          type="geojson"
          data={{ type: 'FeatureCollection', features: polygons }}
        >
          <DrawControl
            onCreate={handleCreatePolygon}
            onSelect={handleSelectPolygon}
            onChangePolygon={handleChangePolygon}
          />
        </Source>
      )}
      {role === EUserRole.MODERATOR && (
        <Source
          id="polygon-source"
          key="polygon-source"
          type="geojson"
          data={{ type: 'FeatureCollection', features: polygons }}
        >
          <Layer
            id="polygon-layer"
            key="polygon-layer"
            type="fill"
            paint={{
              'fill-color': '#4282E6',
              'fill-opacity': 0.4,
            }}
          />
          <Layer
            id="polygon-outline-layer"
            key="polygon-outline-layer"
            type="line"
            paint={{
              'line-color': '#1d65d4',
              'line-width': 4,
            }}
          />
        </Source>
      )}
    </Map>
  );
};
