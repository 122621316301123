import { useParams } from 'react-router-dom';

import { RecordDisplay } from './components/RecordDisplay';

export const RecordScreen = () => {
  const { id: recordId } = useParams();

  return (
    <div className="m-[24px] flex flex-col gap-[16px]">
      {recordId && <RecordDisplay recordId={recordId} />}
      {!recordId && <h2>Запись не найдена</h2>}
    </div>
  );
};
