import { FC, memo } from 'react';
import { useParticipants } from '@livekit/components-react';

import { getWordDayForm } from 'utils';

const Presence: FC = () => {
  const participants = useParticipants();

  const presencePluralString = getWordDayForm(participants.length, [
    'зритель',
    'зрителя',
    'зрителей',
  ]);

  return (
    <span className="tpg-h4">
      {participants.length} {presencePluralString}
    </span>
  );
};

export default memo(Presence);
