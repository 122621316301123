import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LiveKitRoom } from '@livekit/components-react';
import { SEPARATOR } from 'constants/livekit';
import { useAppDispatch, useAppSelector } from 'hooks';
import { roomSelector } from 'store/slices/room';
import { getLiveKitTokenThunk } from 'store/slices/room/actions';

import { ViewerPlayer } from 'components/StreamPlayerContainer/components/ViewerPlayer';
import Loader from 'components/ui/Loader';
import { Tag } from 'components/ui/Tag';

import { ExpandableText } from './components/ExpandableText';

// temporary mocks, will be removed in the future
const tags = ['Paradox', 'EU4', 'Матч'];

const text =
  'Всем привет! На связи Папа Квази и мы здесь проходим стратегии и тактики на высокой сложности, выполняем хардкорные ачивки, обустраиваем постапокалиптические поселения и не только! Присоединяйся - тут интересно ;)';

export const StreamScreen = () => {
  const { token } = useAppSelector(roomSelector);

  const [streamName, setStreamName] = useState('');

  const { id: liveKitRoomName } = useParams();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (liveKitRoomName) {
      const [clanId, roomName] = liveKitRoomName.split(SEPARATOR);

      setStreamName(liveKitRoomName);

      dispatch(getLiveKitTokenThunk({ clanId, roomName }));
    }
  }, []);

  if (!token || !streamName) return <Loader />;

  return (
    <div className="m-[24px] flex flex-col gap-[16px]">
      <LiveKitRoom token={token} serverUrl={process.env.REACT_APP_LIVEKIT_API}>
        <ViewerPlayer streamName={streamName} />
      </LiveKitRoom>
      <div className="flex flex-row gap-y-[8px] gap-x-[4px] flex-wrap max-w-[350px]">
        {tags.map((tag) => (
          <Tag key={tag} label={tag} />
        ))}
      </div>
      <ExpandableText text={text} />
    </div>
  );
};
