import { useSearchParams } from 'react-router-dom';

import { ClansTable } from './components/ClansTable';
import { PlayersTable } from './components/PlayersTable';

export const AdminScreen = () => {
  const [searchParams] = useSearchParams();

  return (
    <div>
      {searchParams.get('filter') === 'players' ? (
        <PlayersTable />
      ) : (
        <ClansTable />
      )}
    </div>
  );
};
