import { FC, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { ITag } from 'interfaces';
import poster from 'mock/images/stream.png';
import { ID } from 'types';

type CardProps = {
  type: 'record' | 'stream';
  cardId: ID;
  clanId: ID;
  src?: string;
  className?: string;
  host?: string;
  tags?: ITag[];
};

export const Card: FC<CardProps> = ({
  cardId,
  src,
  tags,
  type,
  className,
  clanId,
}) => {
  const navigate = useNavigate();

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    navigate(`/${type}/${cardId}`, {
      state: { clanId },
    });
  };

  return (
    <div
      className={cn(
        'relative cursor-pointer border-solid border-tpg_light border-[1px] rounded-[10px] hover:[&>div]:after:opacity-0 [&>div]:after:transition-opacity',
        className
      )}
      onClick={handleClick}
    >
      <div
        className="w-full h-full after:content-[''] after:absolute after:top-0 after:left-0 after:w-full 
        after:h-full after:rounded-[10px] after:bg-[linear-gradient(180deg,_#161824,_#17192538,_#161824)]"
      >
        <img
          className="rounded-[10px] w-full h-full"
          src={src || poster}
          alt="stream preview"
        />
      </div>
      <div className="absolute tpg-c2 top-[17px] left-[32px] pointer-events-none">
        <span
          className={cn(
            "relative before:content-[''] before:absolute before:top-[50%] before:left-[-15px] before:w-[8px] before:h-[8px] before:rounded-[50%] before:translate-y-[-50%]",
            {
              ['before:bg-error text-error']: type === 'stream',
              ['before:bg-tpg_base text-tpg_base']: type === 'record',
            }
          )}
        >
          {type === 'stream' ? 'В эфире' : 'Запись'}
        </span>
      </div>
      <div className="absolute flex flex-row gap-[4px] tpg-b2 top-[12px] right-[16px] pointer-events-none">
        {/* temporary mock */}
        <span>{'15:40'}</span>
        {/* TODO: add "date" field */}
      </div>
      {!!tags?.length && (
        <div className="flex flex-row flex-wrap gap-[4px] absolute bottom-[8px] left-[16px] pointer-events-none">
          {tags.map(({ title, id }) => (
            <div
              key={id}
              className="flex px-[12px] py-[5px] bg-ultrablack rounded-[5px]"
            >
              <span className="tpg-c2">{title}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
