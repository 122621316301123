import {
  ILoggedInRequest,
  ILoginResponse,
  TLoggedInResponse,
} from 'interfaces';
import axios from 'services/axios';

import {
  IAccountPasswordThunkData,
  TAccountEmailThunkData,
} from './type/account';

export const login = () =>
  axios.post<ILoginResponse>(
    'login',
    {},
    { params: { access_type: 'offline' } }
  );

export const loggedIn = (data: ILoggedInRequest) =>
  axios.post<TLoggedInResponse>('logged-in', data);

export const changeEmail = (data: TAccountEmailThunkData) =>
  axios.post<TLoggedInResponse>('/change-email', data);

export const changePassword = (data: IAccountPasswordThunkData) =>
  axios.post<TLoggedInResponse>('/change-password', data);
