import { FC, ReactNode } from 'react';

import { Header } from 'components/Header';

import './style.scss';

type TLayoutProps = {
  children: ReactNode;
  rightHeaderButton?: ReactNode;
  leftContent?: ReactNode;
  centralContent?: ReactNode;
  isPageControlPanel?: boolean;
};

export const MainLayout: FC<TLayoutProps> = ({
  isPageControlPanel = true,
  children,
  rightHeaderButton,
  centralContent,
  leftContent,
}) => {
  return (
    <main className="main-container flex flex-col">
      <Header
        rightButton={rightHeaderButton}
        centralContent={centralContent}
        leftContent={leftContent}
        isPageControlPanel={isPageControlPanel}
      />
      <div className="flex-auto overflow-auto p-[24px]">{children}</div>
    </main>
  );
};
