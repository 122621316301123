import { ChangeEvent, Dispatch, FC, SetStateAction, useState } from 'react';
import cn from 'classnames';
import { ReactComponent as FullscreenIcon } from 'images/newIcons/fullSize.svg';
import { ReactComponent as PauseIcon } from 'images/newIcons/pause.svg';
import { ReactComponent as PlayIcon } from 'images/newIcons/play.svg';
import { RemoteTrack } from 'livekit-client';

import { defaultVolume } from './constants';

interface IControlPanelProps {
  videoEl: HTMLVideoElement | null;
  playerEl: HTMLDivElement | null;
  tracks: RemoteTrack[];
  isPause: boolean;
  onPause: Dispatch<SetStateAction<boolean>>;
}

export const ControlPanel: FC<IControlPanelProps> = ({
  videoEl,
  playerEl,
  tracks,
  isPause,
  onPause,
}) => {
  const [volume, setVolume] = useState(defaultVolume);
  const [isFullscreen, setFullscreen] = useState(false);

  const onPauseChange = () => {
    if (videoEl) {
      if (isPause) {
        onPause(false);

        tracks.forEach((track) => {
          track.attach(videoEl);
        });
      } else {
        onPause(true);

        tracks.forEach((track) => {
          track.detach();
        });
      }
    }
  };

  const onFullScreen = () => {
    if (isFullscreen) {
      document.exitFullscreen().catch((err) => console.error(err));
      setFullscreen(false);
    } else if (playerEl) {
      playerEl.requestFullscreen().catch((err) => console.error(err));
      setFullscreen(true);
    }
  };

  const onVolumeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setVolume(+e.target.value);

    if (videoEl) {
      videoEl.muted = e.target.value === '0';
      videoEl.volume = +e.target.value * 0.01;
    }
  };

  return (
    <div
      className={cn(
        'absolute top-0 h-full w-full opacity-0 hover:opacity-100',
        videoEl && 'hover:transition-all'
      )}
    >
      <div className="absolute bottom-0 flex h-14 w-full items-center justify-between bg-gradient-to-t from-neutral-900 px-4">
        <div className="flex items-center gap-2">
          <div
            className="text-white flex items-center cursor-pointer max-w-[2ex]"
            onClick={onPauseChange}
          >
            {isPause ? <PlayIcon /> : <PauseIcon />}
          </div>
          <input
            type="range"
            onChange={onVolumeChange}
            className="ml-1 h-0.5 w-24 cursor-pointer appearance-none rounded-full bg-white accent-white"
            value={volume}
          />
        </div>
        <div className="flex items-center justify-center gap-4">
          <div
            className="text-white flex items-centыer justify-center"
            onClick={onFullScreen}
          >
            <FullscreenIcon />
          </div>
        </div>
      </div>
    </div>
  );
};
