import { FC, MouseEvent, useState } from 'react';
import cn from 'classnames';

import { generatePassword } from 'utils';

interface GeneratePasswordProps {
  onClick: (value: string) => void;
  label?: string;
}

export const GeneratePassword: FC<GeneratePasswordProps> = ({
  onClick,
  label = 'Cгенерировать новый',
}) => {
  const [isGenerated, setGenerated] = useState(false);

  const handleGeneratePassword = (event: MouseEvent) => {
    if (!isGenerated) {
      event.stopPropagation();

      setGenerated(true);

      const password = generatePassword(10);

      onClick(password);
    }
  };

  return (
    <span
      className={cn('tpg-c2 text-base', {
        'text-bright_product hover:text-main_product cursor-pointer':
          !isGenerated,
      })}
      onClick={handleGeneratePassword}
    >
      {isGenerated ? 'Cгенерирован' : label}
    </span>
  );
};
