import { FC } from 'react';
import { usePlayer } from 'hooks/usePlayer';
import { IVideoPlayer } from 'types/video-player';

import 'video.js/dist/video-js.css';
import './style.scss';

export const Player: FC<IVideoPlayer> = ({ src }) => {
  const playerRef = usePlayer({ src });

  return (
    <div className="flex relative w-full border-solid border-tpg_light border-[1px] rounded-[10px]">
      <video ref={playerRef} className="video-js rounded-[10px]" />
    </div>
  );
};
