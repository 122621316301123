import { FC, useId, useState } from 'react';
import { EUserRole } from 'constants/profile';
import { IOption } from 'interfaces';
import { IPlayer } from 'interfaces/player';
import { roleOptions } from 'mock';

import { CopyIcon } from 'components/CopyIcon';
import { GeneratePassword } from 'components/GeneratePassword';
import { Button, Select, TextInput } from 'components/ui';
import { maskPassword } from 'utils';

interface AddPlayerCardProps {
  onClose: () => void;
  setNewPlayer: (data: Omit<IPlayer, 'launchedStreams'>) => void;
}

export const AddPlayerCard: FC<AddPlayerCardProps> = ({
  onClose,
  setNewPlayer,
}) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [selectedTechnique, setSelectedTechnique] = useState<IOption>({
    value: 'Не выбрано',
    label: 'Не выбрано',
  });

  const [selectedRole, setSelectedRole] = useState<IOption>({
    value: 'Не выбрано',
    label: 'Не выбрано',
  });

  const [selectedAccess, setSelectedAccess] = useState<IOption[]>([]);

  const id = useId();

  const handleChangeName = (value: string) => {
    setName(value);
  };

  const handleChangeLogin = (value: string) => {
    setEmail(value);
  };

  const handleSelectRole = (value: IOption | IOption[]) => {
    if (!Array.isArray(value)) {
      setSelectedRole(value);
    }
  };

  const handleClose = () => {
    onClose();
    setName('');
    setEmail('');
    setPassword('');

    setSelectedRole({
      value: '',
      label: '',
    });

    setSelectedAccess([]);
  };

  const handleSave = () => {
    const access = selectedAccess.map((access) => access.value);

    setNewPlayer({
      id,
      name,
      role: selectedRole.value as EUserRole,
      clans: [],
      status: '',
      access,
      external_id: email,
      email,
      password,
      roles: [],
    });

    handleClose();
  };

  const handleGeneratePassword = (value: string) => {
    setPassword(value);
  };

  return (
    <div className="flex flex-col gap-[16px]">
      <div className="border border-solid rounded-[10px] border-dark_product">
        <div className="flex flex-col px-[16px] py-[10px]">
          <span className="tpg-c2 text-tpg_light">Игрок</span>
          <TextInput
            onChange={handleChangeName}
            value={name}
            inputClassName="bg-ultrablack h-[20px] !pl-0"
          />
        </div>
        <Select
          value={selectedRole}
          options={roleOptions}
          onSelect={handleSelectRole}
          placeholder="Роль"
          className="pl-[16px] border-t border-solid border-t-dark_product"
        />
        <div className="flex flex-col px-[16px] py-[10px] border-t border-solid border-t-dark_product">
          <span className="tpg-c2 text-tpg_light">Логин</span>
          <div className="flex flex-row items-center gap-[8px]">
            <TextInput
              onChange={handleChangeLogin}
              value={email}
              inputClassName="bg-ultrablack h-[20px] !pl-0"
              className="w-full"
            />
            <CopyIcon value={email} />
          </div>
        </div>
        <div className="flex flex-col px-[16px] py-[10px] border-t border-solid border-t-dark_product">
          <span className="tpg-c2 text-tpg_light">Пароль</span>
          <div className="flex flex-row justify-between items-center">
            <span className="tpg-c1">{maskPassword(password)}</span>
            <div className="flex flex-row gap-[8px]">
              <GeneratePassword onClick={handleGeneratePassword} />
              {password && <CopyIcon value={password} />}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row gap-[12px]">
        <Button
          onClick={handleClose}
          title="Отмена"
          className="w-full hover:bg-dark_product bg-inherit border-2 border-solid border-dark_product"
        />
        <Button onClick={handleSave} title="Добавить" className="w-full" />
      </div>
    </div>
  );
};
