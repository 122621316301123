import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { clanResponseAdapter } from 'api/helpers/clanAdapter';
import { reducersNames } from 'constants/reducers';
import { IClan } from 'interfaces/clan';
import { TClanResponse } from 'types/clan';
import { ID } from 'types/common';

import {
  deleteClanMemberThunk,
  deleteClanThunk,
  getClanMembersThunk,
  getClansThunk,
  getClanThunk,
  updateClanThunk,
} from './actions';
interface IClanState {
  clans: IClan[];
  pending: boolean;
  error: string | null;
  selectedClan: IClan | null;
}

const initialState: IClanState = {
  clans: [],
  error: null,
  pending: false,
  selectedClan: null,
};

const clanSlice = createSlice({
  name: reducersNames.CLAN,
  initialState,
  reducers: {
    removeClan(state, action: PayloadAction<ID>) {
      state.clans = state.clans.filter((clan) => clan.id !== action.payload);
    },
    setNewClan(state, { payload }: PayloadAction<IClan>) {
      state.selectedClan = { ...payload };
    },
    modifySelected(state, { payload }: PayloadAction<Partial<IClan>>) {
      if (state.selectedClan) {
        state.selectedClan = { ...state.selectedClan, ...payload };
      }
    },
    setSelectedClan(state, { payload }: PayloadAction<ID>) {
      const selectedClan = state.clans.find((clan) => clan.id === payload);

      if (selectedClan) state.selectedClan = selectedClan;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getClansThunk.fulfilled,
        (state, action: PayloadAction<TClanResponse[]>) => {
          state.clans = action.payload.map(clanResponseAdapter);
          state.selectedClan = null;
          state.error = null;
          state.pending = false;
        }
      )
      .addCase(getClansThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(getClansThunk.rejected, (state) => {
        state.pending = false;
        state.error = 'Ошибка загрузки';
      })
      .addCase(getClanThunk.fulfilled, (state, { payload: loadedClan }) => {
        state.selectedClan = clanResponseAdapter(loadedClan);
        state.pending = false;
        state.error = null;
      })
      .addCase(getClanThunk.pending, (state) => {
        state.error = null;
        state.pending = true;
      })
      .addCase(getClanThunk.rejected, (state) => {
        state.pending = false;
        state.error = 'Ошибка загрузки';
      })
      .addCase(getClanMembersThunk.fulfilled, (state, action) => {
        if (state.selectedClan) state.selectedClan.players = action.payload;
      })
      .addCase(updateClanThunk.fulfilled, (state, { meta: { arg } }) => {
        const index = state.clans.findIndex((clan) => clan.id === arg.id);

        state.clans[index] = arg;
        state.error = null;
        state.pending = false;
      })
      .addCase(updateClanThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(updateClanThunk.rejected, (state) => {
        state.pending = false;
        state.error = 'Ошибка загрузки';
      })
      .addCase(deleteClanThunk.fulfilled, (state, { meta: { arg } }) => {
        state.clans = state.clans.filter((clan) => clan.id !== arg);
        state.error = null;
        state.pending = false;
      })
      .addCase(deleteClanThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(deleteClanThunk.rejected, (state) => {
        state.pending = false;
        state.error = 'Ошибка загрузки';
      })
      .addCase(deleteClanMemberThunk.fulfilled, (state) => {
        state.error = null;
        state.pending = false;
      })
      .addCase(deleteClanMemberThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(deleteClanMemberThunk.rejected, (state) => {
        state.pending = false;
        state.error = 'Ошибка загрузки';
      });
  },
});

export const { actions: clanActions, reducer: clanReducer } = clanSlice;

export * from './selectors';
