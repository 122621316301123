import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { notify } from 'utils';

// Respons(E) - интерфейс ответа
// Reques(T) - интерфейс запроса

export const createThunk = <IResponse, IRequest = undefined>(
  name: string,
  axiosRequest: (data: IRequest) => Promise<AxiosResponse<IResponse>>,
  errorMessage?: string,
  callback?: (data: IResponse, request?: IRequest) => IResponse
) =>
  createAsyncThunk<IResponse, IRequest>(name, async (request, thunkAPI) => {
    try {
      const { data } = await axiosRequest(request);

      if (callback) {
        return callback(data, request);
      }

      return data;
    } catch (error) {
      if (errorMessage) {
        notify.error(errorMessage);
      }

      return thunkAPI.rejectWithValue(error);
    }
  });
