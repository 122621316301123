export enum errorMessages {
  GET_PRIVILEGES_ERROR = 'Не удалось получить роль пользователя',
  REGISTER_ERROR = 'Не удалось зарегистрировать игрока',
  DELETE_CLAN_MEMBER = 'Не удалось удалить игрока',
  GET_CLAN = 'Не удалось загрузить данные клана',
  CREATE_CLAN = 'Не удалось создать новый клан',
  UPDATE_CLAN = 'Не удалось обновить данные клана',
  DELETE_CLAN = 'Не удалось удалить выбранный клан',
  GET_ACCOUNT = 'Не загрузить аккаунт',
  UPDATE_ACCOUNT = 'Не удалось обновить аккаунт',
  DELETE_ACCOUNT = 'Не удалось удалить аккаунт',
}
