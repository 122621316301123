import { ValueOf } from 'types';

/**
 * Restrictions on the number of characters that can be entered in the user name field
 */
export const USER_NAME_LIMIT = 100;

/**
 * Restrictions on the number of characters that can be entered in the password field
 */
export const PASSWORD_LIMIT = 20;

export enum EUserRole {
  USER = 'user',
  MODERATOR = 'moderator',
  ADMIN = 'admin',
  ROOT = 'root',
}

export const roleID = {
  [EUserRole.ADMIN]: '00000000000000000000000006',
  [EUserRole.MODERATOR]: '00000000000000000000000005',
  [EUserRole.USER]: '00000000000000000000000004',
};

export const featurePermission: Record<string, ValueOf<EUserRole>[]> = {
  'create-report': [EUserRole.MODERATOR],
  'create-client': [EUserRole.MODERATOR],
  'feature-filter-by-client': [EUserRole.MODERATOR],
  'converter-customer-reports': [EUserRole.MODERATOR],
  'delete-imagery': [EUserRole.MODERATOR],
};
