import { FC } from 'react';
import { IOption } from 'interfaces';
import { TSetState } from 'types';

import { Button } from 'components/ui';
import { MultiSelect } from 'components/ui';
import TextArea from 'components/ui/TextArea';

interface ISaveStreamModalProps {
  streamDescription: string;
  selectedGameTypes: IOption[];
  selectedTags: IOption[];
  setSelectedGameTypes: TSetState<IOption[]>;
  setSelectedTags: TSetState<IOption[]>;
  setStreamDescription: TSetState<string>;
  onClose: () => void;
  onReset: () => void;
}

// temporary mocks, will be removed in the future
const gameTypes = [
  { label: 'матч', value: 'матч' },
  { label: 'прохождение', value: 'прохождение' },
  { label: 'совместная игра', value: 'совместная игра' },
];

const tags = [
  { label: 'paradox', value: 'paradox' },
  { label: 'cтратегия', value: 'cтратегия' },
  { label: 'grand стратегия', value: 'grand стратегия' },
];

export const SaveStreamModal: FC<ISaveStreamModalProps> = ({
  streamDescription,
  selectedGameTypes,
  selectedTags,
  setSelectedGameTypes,
  setSelectedTags,
  setStreamDescription,
  onClose,
  onReset,
}) => {
  return (
    <>
      <span className="tpg-h4">Сохранить стрим</span>
      <div className="flex flex-col gap-[12px]">
        <MultiSelect
          placeholder="Вид игры"
          value={selectedGameTypes}
          options={gameTypes}
          onSelect={setSelectedGameTypes}
        />
        <MultiSelect
          placeholder="Теги"
          value={selectedTags}
          options={tags}
          onSelect={setSelectedTags}
        />
        <TextArea
          placeholder="Описание"
          onChange={setStreamDescription}
          value={streamDescription}
          rows={6}
          textAreaClassName="!resize-none"
        />
        <Button
          onClick={onClose}
          title="Сохранить"
          className="w-full"
          disabled={
            !streamDescription ||
            !selectedGameTypes.length ||
            !selectedTags.length
          }
        />
        <div className="flex flex-row gap-[12px]">
          <Button
            onClick={onReset}
            title="Не сохранять"
            className="w-full hover:bg-dark_product bg-inherit border-2 border-solid border-dark_product"
          />
          <Button
            onClick={onClose}
            title="Возобновить"
            className="w-full hover:bg-dark_product bg-inherit border-2 border-solid border-dark_product"
          />
        </div>
      </div>
    </>
  );
};
