import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EUserRole } from 'constants/profile';
import { useAppDispatch, useAppSelector } from 'hooks';
import { IOption } from 'interfaces';
import { IPlayer } from 'interfaces/player';
import { mockHistory, techniqueOptions } from 'mock';
import { accountSelector, playerSelector } from 'store';
import {
  banAccountThunk,
  updateAccountThunk,
} from 'store/slices/account/actions';
import {
  getAllPlayersThunk,
  registerWithClanThunk,
} from 'store/slices/player/actions';
import { rolesValuesMap } from 'types/player';

import { AdminTable } from 'components/AdminTable';
import { HistoryInfo } from 'components/HistoryInfo';
import Loader from 'components/ui/Loader';
import { notify } from 'utils';

import { AccessList } from './AccessList';
import { BasicInfo } from './BasicInfo';

interface PlayerAdminPanelScreenProps {
  isNewPlayer?: boolean;
}

export type TPlayerInfo = Omit<IPlayer, 'computer_id' | 'access' | 'status'> & {
  status: IOption;
};

const playerInfo: TPlayerInfo = {
  id: 'new',
  name: '',
  role: EUserRole.USER,
  clans: [],
  roles: [],
  status: {
    value: 'Не авторизован',
    label: 'Не авторизован',
  },
  launchedStreams: 0,
  external_id: '',
  email: '',
  password: '',
};

export const PlayerAdminPanelScreen: FC<PlayerAdminPanelScreenProps> = ({
  isNewPlayer,
}) => {
  const { id } = useParams();

  const navigate = useNavigate();

  const { list: players, pending } = useAppSelector(playerSelector);
  const { roles } = useAppSelector(accountSelector);

  //why
  const roleInitialState = roles.find(
    (role) => role.role_name === EUserRole.ADMIN
  );

  const dispatch = useAppDispatch();

  const [initialPlayerValue, setInitialPlayerValue] = useState<TPlayerInfo>();

  const [player, setPlayer] = useState<TPlayerInfo>(playerInfo);
  const [playerRole, setPLayerRole] = useState(player.role);

  useEffect(() => {
    if (!players) {
      dispatch(getAllPlayersThunk());

      return;
    }

    const existedPlayer = players?.find((player) => String(player.id) === id);

    if (isNewPlayer || !existedPlayer) {
      setPlayer(playerInfo);

      return;
    }

    const initialExistedPlayer = {
      id: existedPlayer.id,
      role: existedPlayer.role,
      clans: existedPlayer.clans,
      name: existedPlayer.name,
      roles: existedPlayer?.roles,
      status: {
        value: existedPlayer?.status,
        label: existedPlayer?.status,
      },
      computer: {
        id: existedPlayer?.computer?.id || '',
        name:
          techniqueOptions.find(
            (option) => option.value === existedPlayer?.computer?.name
          )?.label || '',
      },
      launchedStreams: existedPlayer?.launchedStreams,
      external_id: existedPlayer?.email,
      email: existedPlayer?.email,
      password: existedPlayer?.password,
    };

    setInitialPlayerValue(initialExistedPlayer);
    setPlayer(initialExistedPlayer);
    setPLayerRole(initialExistedPlayer.role);
  }, [players]);

  const handleChangePlayerInfo = (info: TPlayerInfo) => {
    setPlayer(info);
  };

  const handleUpdatePlayer = () => {
    if (isNewPlayer && roleInitialState) {
      dispatch(
        registerWithClanThunk({
          clanId: roleInitialState.clan_id,
          password: player.password,
          password2: player.password,
          name: player.name,
          roleId: rolesValuesMap[playerRole],
          email: player.email,
        })
      );

      navigate(-1);
    } else {
      if (
        initialPlayerValue?.password === player.password &&
        initialPlayerValue?.email !== player.email
      ) {
        notify.error('Для смены логина сначала сгенерируйте новый пароль', {
          autoClose: 3000,
        });

        return;
      }

      const isNewPassword =
        initialPlayerValue?.password !== player.password &&
        initialPlayerValue?.email === player.email;

      const isNewEmail =
        initialPlayerValue?.password !== player.password &&
        initialPlayerValue?.email !== player.email;

      dispatch(
        updateAccountThunk({
          id: player.id,
          name: player.name,
          computer_id: player.computer?.id,
          password: player.password,
          isNewPassword: isNewPassword,
          isNewEmail: isNewEmail,
          email: player.email,
          isAddedFromAccount: true,
        })
      );

      navigate(-1);
    }
  };

  const handleRemovePlayer = () => {
    dispatch(banAccountThunk(String(player.id)));

    navigate(-1);
  };

  const clans = useMemo(
    () =>
      player.roles.map((role) => ({
        clanId: role.clan_id,
        nameId: role.clan_name,
        roleId: role.role_id,
        roleName: role.role_name,
      })),
    [player]
  );

  if (!player && !isNewPlayer) {
    return <span>Данные не загрузились</span>;
  }

  if (pending)
    return (
      <div className="h-full w-full absolute top-0 left-0 flex justify-center items-center">
        <Loader />
      </div>
    );

  return (
    <AdminTable
      title={isNewPlayer ? 'Новый игрок' : `Игрок ${player?.name}`}
      listTitle="Список доступов"
      historyTitle="История пользователя"
      isShowFooterController={true}
      isNewData={isNewPlayer}
      info={
        <BasicInfo
          playerInfo={player}
          onChange={handleChangePlayerInfo}
          isNewPlayer={isNewPlayer}
        />
      }
      history={<HistoryInfo history={mockHistory} />}
      list={
        !isNewPlayer && (
          <AccessList accountId={String(player.id)} clans={clans} />
        )
      }
      onSave={handleUpdatePlayer}
      onRemove={handleRemovePlayer}
    />
  );
};
