import { useEffect } from 'react';
import { useRoomContext } from '@livekit/components-react';
import { RoomEvent } from 'livekit-client';
import { RoomEventCallbacks } from 'livekit-client/dist/src/room/Room';

type TRoomEventMap = {
  [key in RoomEvent]?: RoomEventCallbacks[key];
};

export const useRoomListeners = (events: TRoomEventMap) => {
  const room = useRoomContext();

  useEffect(() => {
    Object.entries<RoomEventCallbacks[RoomEvent]>(events).forEach(
      ([key, callback]) => {
        const typedKey = key as RoomEvent;
        room.on(typedKey, callback);
      }
    );

    return () => {
      Object.entries<RoomEventCallbacks[RoomEvent]>(events).forEach(
        ([key, callback]) => {
          const typedKey = key as RoomEvent;
          room.off(typedKey, callback);
        }
      );
    };
  }, []);
};
