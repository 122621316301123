import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks';
import { clansMock } from 'mock';
import { clansSelector } from 'store';
import { getClanThunk } from 'store/slices/clan/actions';

import { Navigation } from 'components/Navigation';

interface IClan {
  index: number;
  name: string;
}

export const HeaderNavigation = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [selectedClan, setSelectedClan] = useState<IClan>();

  const { clans } = useAppSelector(clansSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) {
      dispatch(getClanThunk(id));

      const info = {
        index: clans.findIndex((clan) => clan.id === id),
        name: clans.find((clan) => clan.id === id)?.name,
      };

      if (info.name) {
        setSelectedClan(info as IClan);
      }
    }
  }, [id, clans]);

  const handleClickArrow = (value: number) => {
    if (clans) {
      const maybeSelected = clans[value];

      if (maybeSelected) {
        navigate(`/clan/${maybeSelected.id}`);

        setSelectedClan({
          index: value,
          name: maybeSelected.name,
        });
      }
    }
  };

  return (
    <>
      {selectedClan?.name && (
        <Navigation
          text={selectedClan.name}
          onClickRight={() => handleClickArrow(selectedClan.index + 1)}
          onClickLeft={() => handleClickArrow(selectedClan.index - 1)}
          isDisableRightButton={
            selectedClan.index + 1 === clansMock.clans.length
          }
          isDisableLeftButton={selectedClan.index === 0}
        />
      )}
    </>
  );
};
