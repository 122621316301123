import { FC } from 'react';
import cn from 'classnames';
import { format } from 'date-fns';
import { TDateRange } from 'types';

import { DatePickerDropdown } from '../ui/DatePickerDropdown/DatePickerDropdown';

interface IFilterSectionProps {
  title: string;
  streamDate: (string | null)[];
  setStreamDate: (value: (string | null)[]) => void;
}

export const FilterSection: FC<IFilterSectionProps> = ({
  title,
  streamDate,
  setStreamDate,
}) => {
  const handleDatePicker = (dates: TDateRange) => {
    const formatedDates = new Set(
      dates.map((date) => date && format(date, 'dd.MM.yyyy'))
    );

    setStreamDate(Array.from(formatedDates));
  };

  return (
    <div className="flex flex-row justify-between items-center">
      <span className="tpg-h4">{title}</span>
      <DatePickerDropdown
        onValueChange={handleDatePicker}
        className="mt-5 right-0"
        iconClassName={cn(
          'cursor-pointer',
          streamDate.length && '[&>path]:fill-bright_product'
        )}
      />
    </div>
  );
};
