import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { authSelector, loginThunk } from 'store';

import { Button } from 'components/ui';
import Loader from 'components/ui/Loader';

import './style.scss';

export const Login = () => {
  const dispatch = useAppDispatch();
  const { redirectUrl, pending, error } = useAppSelector(authSelector);

  const tryRedirect = () => {
    dispatch(loginThunk());
  };

  useEffect(() => {
    tryRedirect();
  }, []);

  return (
    <div className="login">
      <div className="login_container">
        {pending && <Loader />}
        {(error || !redirectUrl) && (
          <>
            {error}
            <Button
              type="button"
              className="w-full mt-8"
              isLoading={pending}
              onClick={tryRedirect}
              title="Поробовать снова"
            />
          </>
        )}
      </div>
    </div>
  );
};
