import { FC, useCallback, useRef, useState } from 'react';
import { useRoomListeners } from 'hooks';
import { RemoteTrack, RoomEvent } from 'livekit-client';
import poster from 'mock/images/stream2.png';

import { ControlPanel } from '../ControlPanel';

interface IViewerPlayerProps {
  streamName: string;
}

export const ViewerPlayer: FC<IViewerPlayerProps> = () => {
  const videoEl = useRef<HTMLVideoElement>(null);
  const playerEl = useRef<HTMLDivElement>(null);
  const [isPause, setIsPause] = useState(false);
  const [tracks, setTracks] = useState<RemoteTrack[]>([]);

  const attachTracks = useCallback(
    (track: RemoteTrack) => {
      setTracks((prev) => [...prev, track]);

      if (videoEl.current) {
        const ref = videoEl.current;
        track.attach(ref);
      }
    },
    [tracks, videoEl]
  );

  useRoomListeners({
    [RoomEvent.TrackSubscribed]: attachTracks,
  });

  return (
    <div
      className="flex overflow-hidden aspect-video justify-center relative w-full border-solid border-tpg_light border-[1px] rounded-[10px]"
      ref={playerEl}
    >
      <video className="w-full" ref={videoEl} poster={poster} />
      <ControlPanel
        playerEl={playerEl.current}
        videoEl={videoEl.current}
        tracks={tracks}
        isPause={isPause}
        onPause={setIsPause}
      />
    </div>
  );
};
