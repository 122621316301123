import { IRoom } from 'interfaces';
import axios from 'services/axios';
import { ID } from 'types';

import { getClanRoomsAdapter, roomAdapter } from './helpers/roomAdapter';
import {
  IClanRoomsAdapted,
  ICreateRoomInput,
  IGetLiveKitTokenInput,
  ITokenResponse,
} from './types/room';

export const getClanRooms = async (clanId: ID) =>
  axios.get<IClanRoomsAdapted>(`/clans/${clanId}/rooms`, {
    transformResponse: [
      (data) => {
        // TODO: save parse
        const json = JSON.parse(data);

        return getClanRoomsAdapter(json);
      },
    ],
  });

export const getRoomParticipants = ({
  clanId,
  roomName,
}: {
  clanId: string;
  roomName: string;
}) => axios.get(`/clans/${clanId}/rooms/${roomName}/participants`);

export const getLiveKitToken = async ({
  clanId,
  roomName,
}: IGetLiveKitTokenInput) =>
  axios.post<ITokenResponse>(
    `/clans/${clanId}/rooms/${roomName}/exchange-token`
  );

export const createRoom = async ({
  roomName,
  clanId,
  accountId,
}: ICreateRoomInput) =>
  axios.post<IRoom>(
    `/clans/${clanId}/rooms/${roomName}`,
    {
      publisher_id: accountId,
    },
    {
      transformResponse: [
        (data) => {
          // TODO: save parse
          const json = JSON.parse(data);

          return roomAdapter(json);
        },
      ],
    }
  );

export const createRoomChain = async ({
  roomName,
  clanId,
  accountId,
}: ICreateRoomInput) => {
  const {
    data: { livekitName, numParticipants, host },
  } = await createRoom({ roomName, clanId, accountId });

  const response = await getLiveKitToken({ clanId, roomName });

  const {
    data: { token },
  } = response;

  return {
    ...response,
    data: { livekitName, roomName, numParticipants, host, token },
  };
};
