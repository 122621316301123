import { createSlice } from '@reduxjs/toolkit';
import { reducersNames } from 'constants/reducers';
import { enableMapSet } from 'immer';
import { IRecordsSlice, TSliceWithPromiseFlags } from 'interfaces';
import { IRecord } from 'interfaces/record';
import { ID } from 'types';

import { getClanRecordsThunk, getRecordThunk } from './actions';

enableMapSet();

const initialState: TSliceWithPromiseFlags<IRecordsSlice> = {
  records: new Map<ID, IRecord[]>(),
  selectedRecord: null,
  error: '',
  pending: false,
};

const recordsSlice = createSlice({
  name: reducersNames.STREAM,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(
        getClanRecordsThunk.fulfilled,
        (state, { payload: { clanId, records } }) => {
          state.records.set(clanId, records);
        }
      )
      .addCase(getRecordThunk.pending, (state) => {
        state.pending = true;
      })
      .addCase(getRecordThunk.fulfilled, (state, { payload }) => {
        state.selectedRecord = payload;
        state.pending = false;
      });
  },
});

export const { actions: recordsActions, reducer: recordsReducer } =
  recordsSlice;

export * from './selectors';
