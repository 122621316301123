import { createSlice } from '@reduxjs/toolkit';
import { SEPARATOR } from 'constants/livekit';
import { reducersNames } from 'constants/reducers';
import { IRoom, IRoomsSlice, TSliceWithPromiseFlags } from 'interfaces';
import { ID } from 'types';

import {
  createRoomThunk,
  getClanRoomsThunk,
  getLiveKitTokenThunk,
} from './actions';

const initialState: TSliceWithPromiseFlags<IRoomsSlice> = {
  rooms: new Map<ID, IRoom[]>(),
  selectedRoom: null,
  error: '',
  pending: false,
  token: '',
};

const roomsSlice = createSlice({
  name: reducersNames.STREAM,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(
        getClanRoomsThunk.fulfilled,
        (state, { payload: { clanId, rooms } }) => {
          state.rooms.set(clanId, rooms);
        }
      )
      .addCase(
        createRoomThunk.fulfilled,
        (
          state,
          { payload: { livekitName, roomName, numParticipants, host, token } }
        ) => {
          state.selectedRoom = {
            livekitName,
            roomName,
            numParticipants,
            host,
            clanId: livekitName.split(SEPARATOR)[0],
          };

          state.token = token;

          state.pending = false;
          state.error = null;
        }
      )
      .addCase(createRoomThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(createRoomThunk.rejected, (state) => {
        state.pending = false;
        state.error = 'Не удалось создать комнату';
      })
      .addCase(
        getLiveKitTokenThunk.fulfilled,
        (state, { payload: { token } }) => {
          state.token = token;
        }
      );
  },
});

export const { actions: roomActions, reducer: roomReducer } = roomsSlice;

export * from './selectors';
