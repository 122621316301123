import { FC, ReactElement, ReactNode, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { navs } from 'constants/navs';
import { authRoutes, navigation } from 'constants/routes';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  authActions,
  authSelector,
  streamActions,
  streamSelector,
} from 'store';

import { AddButton } from 'components/AddButton';
import { TextInput } from 'components/ui';
import ConfirmModal from 'components/ui/Modal/ConfirmModal';

import HeaderLink from './HeaderLink';

import './style.scss';

type TNavItem = {
  link: string;
  icon?: ReactElement;
  title: string;
};

interface IHeaderProps {
  isPageControlPanel?: boolean;
  leftContent?: ReactNode;
  centralContent?: ReactNode;
  rightButton?: ReactNode;
}

export const Header: FC<IHeaderProps> = ({
  isPageControlPanel = true,
  leftContent,
  centralContent,
  rightButton,
}) => {
  const { role } = useAppSelector(authSelector);
  const { searchQuery } = useAppSelector(streamSelector);

  const dispatch = useAppDispatch();

  const [isOpenModal, setOpenModal] = useState(false);

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(authActions.logout());
    setTimeout(navigate, 100, authRoutes.SIGN_IN);
  };

  const handleStartStream = () => {
    navigate('/stream-launch');
  };

  const renderNavItem = ({ link, title, icon }: TNavItem, idx: number) => (
    <HeaderLink
      isActive={!!link && pathname.includes(link)}
      link={link}
      title={title}
      passiveIcon={icon || undefined}
      key={`link-${idx}`}
    />
  );

  return (
    <>
      <div className="flex flex-col">
        <nav className="header flex justify-center">
          <div
            className="tpg-h3 cursor-pointer flex flex-row content-center"
            onClick={() => navigate(navigation[role])}
          >
            <span>ASTRA</span>
            <span className="text-bright_product">S</span>
          </div>
          <div className="header__links">
            <div className="absolute left-1/2 -translate-x-1/2 flex flex-row ">
              {navs[role].map(renderNavItem)}
            </div>
          </div>
          <span
            className="cursor-pointer tpg-b1 text-tpg_base hover:text-bright_product transition"
            onClick={() => setOpenModal(true)}
          >
            Выйти
          </span>
        </nav>
        {isPageControlPanel && (
          <div className="w-full h-[48px] flex flex-row items-center justify-between bg-dark sticky top-0 z-10 pr-[24px]">
            {!leftContent ? (
              <TextInput
                value={searchQuery}
                placeholder="Начните поиск"
                searchIcon
                isReset
                className="w-full bg-dark max-w-[540px] xl:max-w-[300px]"
                theme="dark"
                onChange={(value) =>
                  dispatch(streamActions.setQuerySearch(value))
                }
              />
            ) : (
              leftContent
            )}
            {centralContent && centralContent}
            {!rightButton ? (
              <AddButton label="Запустить стрим" onClick={handleStartStream} />
            ) : (
              rightButton
            )}
          </div>
        )}
      </div>
      {isOpenModal && (
        <ConfirmModal
          title="Вы уверены, что хотите выйти?"
          description="У нас ещё много работы и врагов."
          confirmText="Да, я хочу выйти"
          onConfirm={handleLogout}
          onClose={() => {
            setOpenModal(false);
          }}
        />
      )}
    </>
  );
};
