import { IPlayer } from 'interfaces/player';
import axios from 'services/axios';
import { ID } from 'types';

import { IAccountRoleThunkData, IAccountThunkData } from './type/account';

export const getAccount = (accountId: ID) =>
  axios.get(`/accounts/${accountId}`);

export const getAccounts = () => axios.get<IPlayer[]>('/accounts');

export const updateAccount = ({
  id,
  name,
  computer_id,
  metadata,
}: Omit<IAccountThunkData, 'clanId'>) =>
  axios.patch(`/accounts/${id}`, {
    name,
    computer_id: computer_id || null,
    metadata,
  });

export const updateAccountRole = ({
  role_id,
  account_id,
  clan_id,
}: IAccountRoleThunkData) =>
  axios.put(`/accounts/${account_id}/roles`, {
    role_id,
    clan_id,
  });

export const banAccount = (id: string) =>
  axios.post('/ban', { account_id: id });
