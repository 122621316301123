import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { IRoom } from 'interfaces';
import poster from 'mock/images/stream.png';
import { ID } from 'types/common';

interface CardListProps {
  rooms: IRoom[];
  clanId: ID;
  selectedStreamId: string;
  onSelect: (id: string) => void;
}

export const CardList: FC<CardListProps> = ({
  rooms,
  clanId,
  selectedStreamId,
  onSelect,
}) => {
  const navigate = useNavigate();

  const isSelectedStream = useCallback(
    (id: string, index: number) => {
      if (selectedStreamId === id) {
        if (rooms.length < 5) {
          return true;
        }

        if (rooms.length > 4 && index < 3) {
          return true;
        }
      }

      return false;
    },
    [selectedStreamId, rooms]
  );

  const cards = rooms.length > 4 ? rooms.slice(0, 4) : rooms;

  const handleSelectStream = (id: string, index: number) => {
    if (index === 3 && rooms.length > 4) {
      navigate(`/clan/${clanId}`);
    }

    onSelect(id);
  };

  return (
    <div className="flex flex-row gap-[8px] h-[116px]">
      {cards.map(({ livekitName }, index) => (
        <div
          key={livekitName}
          className={cn(
            "relative h-full after:content-[''] after:absolute after:top-0 after:left-0 after:w-full after:h-full after:rounded-[5px] cursor-pointer",
            {
              'after:border-[2px] after:border-solid after:border-bright_product after:rounded-[5px]':
                isSelectedStream(livekitName, index),
            }
          )}
          onClick={() => handleSelectStream(livekitName, index)}
        >
          <img
            className="rounded-[5px] w-full h-full object-cover"
            src={poster}
            alt="stream preview"
          />
          {index === 3 && rooms.length > 4 && (
            <div className="absolute flex items-center justify-center rounded-[5px] h-full w-full top-0 bg-[#171925e6] text-bright_product">
              {rooms.length - cards.length}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
