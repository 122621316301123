import { FC, useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import './style.scss';

interface ExpandableTextProps {
  text: string;
  isExpanded: boolean;
  setExpanded: (flag: boolean) => void;
  className?: string;
}

export const ExpandableText: FC<ExpandableTextProps> = ({
  text,
  isExpanded,
  setExpanded,
  className,
}) => {
  const [isLongText, setLongText] = useState(false);

  const textRef = useRef(null);

  useEffect(() => {
    const resizeHandler = () => {
      if (textRef.current) {
        const { offsetHeight, scrollHeight } = textRef.current;

        setLongText(scrollHeight > offsetHeight);
      }
    };

    resizeHandler();

    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  const handleExpand = () => {
    setExpanded(!isExpanded);
  };

  return (
    <div className={cn('flex flex-col w-full', className)}>
      <span ref={textRef} className={cn('text', isExpanded && 'show')}>
        {text}
      </span>
      {isLongText && (
        <div className="cursor-pointer w-max" onClick={handleExpand}>
          <span className="text-bright_product">
            {isExpanded ? 'Скрыть' : 'Показать больше'}
          </span>
        </div>
      )}
    </div>
  );
};
