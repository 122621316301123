import { useParams } from 'react-router-dom';

import { RecordSection } from 'components/RecordSection';

import { OnlineSection } from './sections/OnlineSection';

export const StreamsScreen = () => {
  const { id: clanId } = useParams();

  return (
    <div className="flex flex-col gap-[16px]">
      {clanId && (
        <>
          <OnlineSection clanId={clanId} />
          <RecordSection clanId={clanId} />
        </>
      )}
    </div>
  );
};
