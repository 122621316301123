import { FC, useEffect, useMemo, useState } from 'react';
import { getClans } from 'api/clans';
import { ReactComponent as PlusIcon } from 'images/newIcons/plus.svg';
import { IOption } from 'interfaces';
import { IClanPlayer } from 'interfaces/player';
import { rolesValuesMap } from 'types/player';

import { getWordDayForm, notify } from 'utils';

import { AccessTable } from './components/AccessTable';
import { AddAccessCard } from './components/AddAccessCard';

interface AccessListProps {
  clans: IClanPlayer[];
  accountId: string;
}

export const AccessList: FC<AccessListProps> = ({ clans, accountId }) => {
  const [isNewAccess, setNewAccess] = useState(false);
  const [clanOptions, setClanOptions] = useState<IOption[]>([]);

  useEffect(() => {
    const getClansData = async () => {
      try {
        const { data } = await getClans();

        const options = data.map(({ id, name }) => ({
          value: String(id),
          label: name,
        }));

        setClanOptions(options);
      } catch (error) {
        notify.warning('Невозможно получить данные кланов');
      }
    };

    getClansData();
  }, []);

  const firteredClans = useMemo(
    () => clans.filter((clan) => clan.roleId !== rolesValuesMap.root),
    [clans]
  );

  const wordForm = getWordDayForm(firteredClans?.length, [
    'доступ',
    'доступа',
    'доступов',
  ]);

  const handleOpenCardAccess = () => {
    setNewAccess(true);
  };

  const handleClose = () => {
    setNewAccess(false);
  };

  return (
    <div className="w-6/12 min-h-full overflow-auto p-[16px]">
      <div className="flex flex-row justify-between bg-ultrablack pb-[16px] sticky top-0 z-[2]">
        <span>{`${firteredClans?.length} ${wordForm}`}</span>
        <div
          className="group cursor-pointer flex items-center justify-center transition"
          onClick={handleOpenCardAccess}
        >
          <PlusIcon className="scale-125 group-hover:[&>g>path]:fill-bright_product [&>g>path]:transition" />
        </div>
      </div>
      <div className="flex flex-col gap-[16px] pt-[8px] pb-[16px]">
        {isNewAccess && (
          <AddAccessCard
            accountId={accountId}
            clanOptions={clanOptions}
            onClose={handleClose}
          />
        )}
        {firteredClans?.map((clan) => (
          <AccessTable
            key={clan.clanId}
            accountId={accountId}
            clanOptions={clanOptions}
            {...clan}
          />
        ))}
      </div>
    </div>
  );
};
