import { getClanRecords, getRecord } from 'api/records';
import { asyncActionsNames } from 'constants/reducers';
import { IClanRecords, IRecord } from 'interfaces/record';
import { ID } from 'types';

import { createThunk } from 'utils';

export const getClanRecordsThunk = createThunk<IClanRecords, ID>(
  asyncActionsNames.CET_CLAN_RECORDS,
  getClanRecords
);

export const getRecordThunk = createThunk<IRecord, ID>(
  asyncActionsNames.GET_RECORD,
  getRecord
);
