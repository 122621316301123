import axios from 'services/axios';

export type BaseJSON = { [key: string]: string | number | BaseJSON };

export type TRegisterRequest = {
  email?: string;
  name: string;
  password: string;
  password2?: string;
};

export const register = ({
  email,
  password,
  password2 = password,
  name,
}: TRegisterRequest) =>
  axios.post('/register', { email, password, password2, name });
