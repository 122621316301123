import { ElementType, FC, ReactNode } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { EUserRole } from 'constants/profile';
import { appRoutes, authRoutes, navigation } from 'constants/routes';
import { useAppSelector } from 'hooks';
import { MainLayout } from 'layouts/MainLayout';
import { AdminScreen } from 'routes/Admin';
import { RightHeaderContent } from 'routes/Admin/components/RightHeaderContent';
import { ClanAdminPanelScreen } from 'routes/ClanAdminPanel';
import { ClansScreen } from 'routes/Clans';
import { HeaderNavigation } from 'routes/Clans/HeaderNavigation';
import { LoggedInScreen } from 'routes/LoggedIn';
import { Login } from 'routes/Login';
import { MapScreen } from 'routes/Map';
import { mapboxDraw } from 'routes/Map/components/MapComponent/DrawControl';
import { PlayerAdminPanelScreen } from 'routes/PlayerAdminPanel';
import { RecordScreen } from 'routes/Record';
import { RecordsScreen } from 'routes/RecordsScreen';
import { StreamScreen } from 'routes/Stream';
import { StreamLaunchScreen } from 'routes/StreamLaunch';
import { StreamsScreen } from 'routes/Streams';
import { authSelector } from 'store';

import { AddButton } from 'components/AddButton';
import { BackButton } from 'components/BackButton';
import NotFound from 'components/NotFound';
import { getToken } from 'utils';

type TRouteProps = {
  path: string;
  component: ReactNode;
  layout: ElementType | null;
  rightHeaderButton?: ReactNode;
  leftContent?: ReactNode;
  centralContent?: ReactNode;
  isPageControlPanel?: boolean;
};

const authPages = Object.values(authRoutes);

const publicPages = Object.values({
  ...appRoutes,
});

const getProperRedirect = (path: string) => {
  const { role } = useAppSelector(authSelector);

  const accessToken = getToken();

  if (!accessToken && publicPages.includes(path)) {
    return <Navigate to={authRoutes.SIGN_IN} replace />;
  }

  if (accessToken && authPages.includes(path)) {
    return <Navigate to={navigation[role]} replace />;
  }

  return null;
};

const AppRoute: FC<TRouteProps> = ({
  component,
  layout,
  path,
  rightHeaderButton,
  leftContent,
  centralContent,
  isPageControlPanel,
}) => {
  const redirect = getProperRedirect(path);

  if (redirect) return redirect;

  if (layout) {
    const Layout = layout;

    return (
      <Layout
        rightHeaderButton={rightHeaderButton && rightHeaderButton}
        centralContent={centralContent && centralContent}
        leftContent={leftContent && leftContent}
        isPageControlPanel={isPageControlPanel}
      >
        {component}
      </Layout>
    );
  }

  return <>{component}</>;
};

export const App: FC = (): JSX.Element => {
  const { role } = useAppSelector(authSelector);

  return (
    <Routes>
      <Route
        path={authRoutes.SIGN_IN}
        element={
          <AppRoute
            path={authRoutes.SIGN_IN}
            component={<Login />}
            layout={null}
          />
        }
      />
      <Route path={authRoutes.LOGGED_IN} element={<LoggedInScreen />} />
      <Route
        path={appRoutes.STREAM_LAUNCH}
        element={
          <AppRoute
            path={appRoutes.STREAM_LAUNCH}
            component={<StreamLaunchScreen />}
            layout={MainLayout}
            rightHeaderButton={<BackButton />}
          />
        }
      />
      <Route
        path={appRoutes.STREAM}
        element={
          <AppRoute
            path={appRoutes.STREAM}
            component={<StreamScreen />}
            layout={MainLayout}
            rightHeaderButton={<BackButton />}
          />
        }
      />
      <Route
        path={appRoutes.RECORD}
        element={
          <AppRoute
            path={appRoutes.RECORD}
            component={<RecordScreen />}
            layout={MainLayout}
            rightHeaderButton={<BackButton />}
          />
        }
      />
      {EUserRole.ADMIN === role && (
        <>
          <Route
            path={appRoutes.ADMIN_NEW_CLAN}
            element={
              <AppRoute
                path={appRoutes.ADMIN_NEW_CLAN}
                component={<ClanAdminPanelScreen isNewClan />}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={appRoutes.ADMIN}
            element={
              <AppRoute
                path={appRoutes.ADMIN}
                component={<AdminScreen />}
                layout={MainLayout}
                rightHeaderButton={<RightHeaderContent />}
              />
            }
          />
        </>
      )}
      {(EUserRole.MODERATOR === role || EUserRole.ADMIN === role) && (
        <>
          <Route
            path={appRoutes.CLANS}
            element={
              <AppRoute
                path={appRoutes.CLANS}
                component={<ClansScreen />}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={appRoutes.CLAN}
            element={
              <AppRoute
                path={appRoutes.CLAN}
                component={<StreamsScreen />}
                layout={MainLayout}
                rightHeaderButton={<BackButton path="/clans" />}
                centralContent={<HeaderNavigation />}
              />
            }
          />
          <Route
            path={
              EUserRole.MODERATOR === role
                ? appRoutes.ADMIN
                : appRoutes.ADMIN_CLAN
            }
            element={
              <AppRoute
                path={
                  EUserRole.MODERATOR === role
                    ? appRoutes.ADMIN
                    : appRoutes.ADMIN_CLAN
                }
                component={<ClanAdminPanelScreen />}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={appRoutes.CLAN_RECORDS}
            element={
              <AppRoute
                path={appRoutes.CLAN_RECORDS}
                component={<RecordsScreen />}
                rightHeaderButton={<BackButton />}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={appRoutes.MAP}
            element={
              <AppRoute
                path={appRoutes.MAP}
                component={<MapScreen />}
                layout={MainLayout}
                rightHeaderButton={
                  EUserRole.ADMIN === role && (
                    <AddButton
                      label="Добавить клан"
                      onClick={() => mapboxDraw.changeMode('draw_polygon')}
                    />
                  )
                }
                isPageControlPanel={false}
              />
            }
          />
          <Route
            path={appRoutes.ADMIN_PLAYER}
            element={
              <AppRoute
                path={appRoutes.ADMIN_PLAYER}
                component={<PlayerAdminPanelScreen />}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={appRoutes.ADMIN_NEW_PLAYER}
            element={
              <AppRoute
                path={appRoutes.ADMIN_NEW_PLAYER}
                component={<PlayerAdminPanelScreen isNewPlayer />}
                layout={MainLayout}
              />
            }
          />
        </>
      )}
      {EUserRole.USER === role && (
        <Route
          path={appRoutes.STREAMS}
          element={
            <AppRoute
              path={appRoutes.STREAMS}
              component={<StreamsScreen />}
              layout={MainLayout}
            />
          }
        />
      )}
      <Route path="/" element={<Navigate replace to={navigation[role]} />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
