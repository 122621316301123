import { createRoomChain, getClanRooms, getLiveKitToken } from 'api/room';
import {
  IClanRoomsAdapted,
  ICreateRoomChainResponse,
  ICreateRoomInput,
  IGetLiveKitTokenInput,
  ITokenResponse,
} from 'api/types/room';
import { asyncActionsNames, reducersNames } from 'constants/reducers';
import { ID } from 'types';

import { createThunk } from 'utils';

export const getClanRoomsThunk = createThunk<IClanRoomsAdapted, ID>(
  `${reducersNames.ROOM}/${asyncActionsNames.GET_CLANS_ROOMS}`,
  getClanRooms
);

export const getLiveKitTokenThunk = createThunk<
  ITokenResponse,
  IGetLiveKitTokenInput
>(
  `${reducersNames.ROOM}/${asyncActionsNames.GET_STREAM_TOKEN}`,
  getLiveKitToken
);

export const createRoomThunk = createThunk<
  ICreateRoomChainResponse,
  ICreateRoomInput
>(
  `${reducersNames.ROOM}/${asyncActionsNames.CREATE_ROOM}`,
  createRoomChain,
  'error while create room'
);
