import { FC, useEffect, useState } from 'react';
import { getComputers } from 'api/computer';
import { IOption } from 'interfaces';

import { CopyIcon } from 'components/CopyIcon';
import { GeneratePassword } from 'components/GeneratePassword';
import { Select, TextInput } from 'components/ui';
import { maskPassword, notify } from 'utils';

import { TPlayerInfo } from '.';

interface BasicInfoProps {
  playerInfo: TPlayerInfo;
  onChange: (info: TPlayerInfo) => void;
  isNewPlayer?: boolean;
}

const mockTableHead = [
  {
    id: 'name',
    label: 'Игрок',
  },
  {
    id: 'computer',
    label: 'Компьютер',
  },
  {
    id: 'launchedStreams',
    label: 'Запущено стримов',
  },
  {
    id: 'login',
    label: 'Логин',
  },
  {
    id: 'password',
    label: 'Пароль',
  },
];

export const BasicInfo: FC<BasicInfoProps> = ({
  playerInfo,
  onChange,
  isNewPlayer,
}) => {
  const [computerOptions, setComputerOptions] = useState<IOption[]>([]);

  const [selectedComputer, setSelectedComputer] = useState<IOption>({
    value: '',
    label: '',
  });

  useEffect(() => {
    const getComputersData = async () => {
      try {
        const { data } = await getComputers();

        const options = data.map(({ id, name }) => ({
          value: String(id),
          label: name,
        }));

        const computerInitialValue = options.find(
          (option) => option.value === playerInfo.computer?.id
        );

        setComputerOptions(options);

        if (computerInitialValue) {
          setSelectedComputer(computerInitialValue);
        }
      } catch (error) {
        notify.warning('Невозможно получить данные компьютера');
      }
    };

    getComputersData();
  }, [playerInfo]);

  const handleChangeName = (value: string) => {
    onChange({ ...playerInfo, name: value });
  };

  const handleSelectComputer = (value: IOption | IOption[]) => {
    if (!Array.isArray(value)) {
      setSelectedComputer(value);

      onChange({
        ...playerInfo,
        computer: { id: value.value, name: value.label },
      });
    }
  };

  const handleChangeLogin = (value: string) => {
    onChange({ ...playerInfo, email: value });
  };

  const handleChangePassword = (value: string) => {
    onChange({ ...playerInfo, password: value });
  };

  const passwordInitialValue =
    isNewPlayer && !playerInfo.password ? '' : 'password';

  return (
    <div className="w-6/12 border-r border-solid border-r-tpg_light overflow-auto min-h-full">
      <div className="bg-ultrablack px-[16px] pt-[16px] pb-[8px] sticky top-0 z-[3]">
        <span>Основная информация</span>
      </div>
      <div className="px-[16px] pt-[8px] pb-[16px] [&>div]last:border-b-none">
        <div className="flex flex-row border border-solid border-dark_product rounded-[10px]">
          <div className="flex flex-col w-6/12 border-r border-solid border-r-dark_product min-w-[195px]">
            {mockTableHead.map((head) => (
              <div
                key={head.id}
                className="p-[12px] border-b border-solid border-b-dark_product h-[44px] last:!border-none"
              >
                <span className="tpg-c1">{head.label}</span>
              </div>
            ))}
          </div>
          <div className="flex flex-col w-6/12">
            <div className="p-[12px] border-b border-solid border-b-dark_product h-[44px] text-tpg_base tpg-c1">
              <TextInput
                onChange={handleChangeName}
                placeholder="Введите название"
                value={playerInfo.name}
                inputClassName="bg-ultrablack !h-[19px] !pl-0 !text-tpg_base"
              />
            </div>
            <div className="p-[12px] border-b border-solid border-b-dark_product h-[44px] text-tpg_base tpg-c1">
              <Select
                value={selectedComputer}
                options={computerOptions}
                onSelect={handleSelectComputer}
                className="!min-h-[20px] !pr-0"
              />
            </div>
            <div className="p-[12px] border-b border-solid border-b-dark_product h-[44px] text-tpg_base tpg-c1">
              <span>{playerInfo.launchedStreams ?? 0}</span>
            </div>
            <div className="p-[12px] border-b border-solid border-b-dark_product h-[44px] text-tpg_base tpg-c1">
              <div className="flex flex-row items-center gap-[8px]">
                <TextInput
                  value={playerInfo.email}
                  inputClassName="bg-ultrablack h-[19px] !pl-0 !text-tpg_base"
                  className="w-full"
                  placeholder="Введите значение"
                  onChange={handleChangeLogin}
                />
                <CopyIcon value={playerInfo.email} />
              </div>
            </div>
            <div className="p-[12px] h-[44px] text-tpg_base tpg-c1">
              <div className="flex flex-row justify-between items-center">
                <span className="tpg-c1 pointer-events-none">
                  {maskPassword(playerInfo.password || passwordInitialValue)}
                </span>
                <div className="flex flex-row gap-[8px]">
                  <GeneratePassword
                    onClick={handleChangePassword}
                    label="Сгенерировать"
                  />
                  {playerInfo.password && (
                    <CopyIcon value={playerInfo.password} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
