import {
  IClanRoomsAdapted,
  IClanRoomsResponse,
  IGetRoomResponse,
} from 'api/types/room';
import { IRoom } from 'interfaces';

export const roomAdapter = (data: IGetRoomResponse): IRoom => {
  const { livekit_name, name, num_participants, publisher_id } = data;

  return {
    livekitName: livekit_name,
    roomName: name,
    numParticipants: num_participants,
    host: publisher_id,
  };
};

export const getClanRoomsAdapter = (
  data: IClanRoomsResponse
): IClanRoomsAdapted => {
  const { clan_id, rooms } = data;

  return {
    clanId: clan_id,
    rooms: rooms.map(roomAdapter),
  };
};
