import { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { recordsSelector } from 'store/slices/records';
import { getRecordThunk } from 'store/slices/records/actions';

import { Player } from 'components/Player';
import Loader from 'components/ui/Loader';

interface IRecordDisplayProps {
  recordId: string;
}

export const RecordDisplay: FC<IRecordDisplayProps> = ({ recordId }) => {
  const dispatch = useAppDispatch();
  const { selectedRecord, pending } = useAppSelector(recordsSelector);

  useEffect(() => {
    dispatch(getRecordThunk(recordId));
  }, [recordId]);

  // TODO: condition render
  return (
    <>
      {pending && <Loader />}
      {selectedRecord && (
        <>
          <Player src="https://egress-test.hb.ru-msk.vkcs.cloud/livekit-demo/track-test.mp4" />
          <div className="flex flex-row gap-y-[8px] gap-x-[4px] flex-wrap max-w-[350px]">
            {/* TODO: tags mapper */}
          </div>
        </>
      )}
    </>
  );
};
