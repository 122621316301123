import { FC, useRef, useState } from 'react';
import cn from 'classnames';
import { useClickOutside } from 'hooks';
import { ReactComponent as Down } from 'images/newIcons/down.svg';
import { IOption } from 'interfaces';

import { Tag } from '../Tag';

interface IMultiSelectProps {
  value: IOption[];
  options: IOption[];
  onSelect: (value: IOption[]) => void;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
}

export const MultiSelect: FC<IMultiSelectProps> = ({
  options,
  onSelect,
  value,
  placeholder,
  className,
  disabled,
}) => {
  const [isOpen, setOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  useClickOutside<HTMLDivElement, void>(selectRef, () => setOpen(false));

  const handleSelectOption = (option: IOption) => {
    if (value.find((selected) => selected.value === option.value)) {
      onSelect(value.filter((selected) => selected.value !== option.value));
    } else {
      onSelect([...value, option]);
    }
  };

  const handleRemoveTag = (event: React.MouseEvent, tag: string) => {
    event.stopPropagation();

    onSelect(value.filter((selected) => selected.value !== tag));
  };

  const handleOpenOptions = () => {
    if (disabled) return;

    setOpen(!isOpen);
  };

  return (
    <div
      className={cn(
        'relative flex flex-row w-full transition delay-200 cursor-pointer bg-light tpg-c1 rounded-md',
        disabled && '!cursor-default',
        className
      )}
      ref={selectRef}
    >
      <div
        className="flex flex-row items-center justify-between w-full min-h-[40px]"
        onClick={handleOpenOptions}
      >
        <div className="w-full overflow-hidden text-ellipsis">
          {!!value.length && (
            <div className="flex flex-wrap p-[4px] gap-[4px]">
              {value.map(({ label, value }) => (
                <Tag
                  key={label}
                  label={label}
                  closeIcon
                  className="max-h-[32px] py-[9px] bg-ultrablack [&>span]:text-tpg_base"
                  onRemove={(event) => handleRemoveTag(event, value)}
                />
              ))}
            </div>
          )}
          {!value.length && placeholder && (
            <span className="whitespace-nowrap text-tpg_base pl-[16px]">
              {placeholder}
            </span>
          )}
        </div>
        <div className="group mr-[16px] flex items-center hover:[&>svg>path]:fill-dark_product w-[20px] h-[20px]">
          <Down
            className={cn(
              'scale-125 group-hover:[&>path]:fill-dark_product [&>path]:fill-tpg_base [&>path]:transition',
              isOpen && 'rotate-180'
            )}
          />
        </div>
      </div>
      {isOpen && (
        <div className="absolute top-[105%] max-h-[212px] left-0 z-50 flex flex-col cursor-pointer rounded-[10px] border border-solid border-tpg_light w-full overflow-auto">
          {options.map((option) => (
            <div
              key={option.value}
              className="flex min-h-[35px] px-[16px] bg-light hover:bg-dark items-center"
              onClick={() => handleSelectOption(option)}
            >
              <span
                className={cn(
                  'tpg-c2 text-tpg_base',
                  value.find((selected) => selected.value === option.value) &&
                    '!text-dark_product'
                )}
              >
                {option.label}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
