import { FC } from 'react';

import { Button, Modal } from 'components/ui';

interface DeleteModalProps {
  title: string;
  onClose: () => void;
  onRemove: () => void;
}

export const DeleteModal: FC<DeleteModalProps> = ({
  title,
  onClose,
  onRemove,
}) => (
  <Modal onClose={onClose} isBlurred>
    <div className="flex flex-col gap-[24px] max-w-[530px] w-[530px] justify-center border border-solid border-tpg_light rounded-[10px] bg-dark p-[48px]">
      <div className="flex flex-col gap-[12px] items-center">
        <span className="tpg-h4">{title}</span>
        <span className="tpg-b2 text-tpg_base">
          Вы можете потерять ценные данные.
        </span>
      </div>
      <div className="flex flex-row gap-[12px]">
        <Button
          onClick={onClose}
          title="Нет, я передумал"
          className="w-full hover:bg-dark_product bg-inherit border-2 border-solid border-dark_product"
        />
        <Button
          onClick={onRemove}
          title="Да, я хочу удалить"
          className="w-full"
        />
      </div>
    </div>
  </Modal>
);
