import { FC, useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import './style.scss';

interface IExpandableTextProps {
  text: string;
}

export const ExpandableText: FC<IExpandableTextProps> = ({ text }) => {
  const [isExpanded, setExpanded] = useState(false);
  const [isLongText, setLongText] = useState(false);

  const textRef = useRef(null);

  useEffect(() => {
    const resizeHandler = () => {
      if (textRef.current) {
        const { offsetHeight, scrollHeight } = textRef.current;

        setLongText(scrollHeight > offsetHeight);
      }
    };

    resizeHandler();

    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  return (
    <div className="flex flex-col w-full">
      <span ref={textRef} className={cn('text', isExpanded && 'show')}>
        {text}
      </span>
      {isLongText && (
        <span
          className="text-bright_product cursor-pointer"
          onClick={() => setExpanded(!isExpanded)}
        >
          {isExpanded ? 'Скрыть' : 'Показать больше'}
        </span>
      )}
    </div>
  );
};
