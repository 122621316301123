import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Switch } from 'components/ui';

interface SwitchWithLabelProps {
  label: string;
}

export const SwitchWithLabel: FC<SwitchWithLabelProps> = ({ label }) => {
  const [isOn, setOn] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('filter') === 'players') {
      setOn(true);
    }
  }, [searchParams]);

  const handleChange = () => {
    setOn(!isOn);

    setSearchParams((prev) => {
      prev.set('filter', `${!isOn ? 'players' : 'clans'}`);

      return prev;
    });
  };

  return (
    <div className="flex flex-row gap-[4px] items-center">
      <span className="tpg-c2">{label}</span>
      <Switch checked={isOn} onChange={handleChange} />
    </div>
  );
};
