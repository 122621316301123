import { FC } from 'react';
import { MapLayerMouseEvent, MapRef, useControl } from 'react-map-gl';
import MapboxDraw, { DrawModeChangeEvent } from '@mapbox/mapbox-gl-draw';
import { IDrawEvent } from 'interfaces';

import { drawStyles } from './config';

type DrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
  onCreate?: (evt: IDrawEvent) => void;
  onSelect?: (evt: MapLayerMouseEvent) => void;
  onChangePolygon?: (event: DrawModeChangeEvent) => void;
};

export const mapboxDraw = new MapboxDraw({
  displayControlsDefault: false,
  styles: drawStyles,
});

export const DrawControl: FC<DrawControlProps> = ({
  onCreate = () => null,
  onSelect = () => null,
  onChangePolygon = () => null,
}) => {
  useControl<MapboxDraw>(
    () => mapboxDraw,
    ({ map }: { map: MapRef }) => {
      map.on('draw.create', onCreate);
      map.on('click', onSelect);
      map.on('draw.selectionchange', onChangePolygon);
    },
    ({ map }: { map: MapRef }) => {
      map.off('draw.create', onCreate);
      map.off('click', onSelect);
      map.off('draw.selectionchange', onChangePolygon);
    }
  );

  return null;
};
