import { IClan } from 'interfaces/clan';
import { IPlayer } from 'interfaces/player';
import axios from 'services/axios';
import { ID } from 'types';
import { TClanResponse } from 'types/clan';

export const getClans = () => axios.get<TClanResponse[]>('/clans');

export const getClan = (id: string) => axios.get<TClanResponse>(`clans/${id}`);

export const getClanMembers = (clanId: ID) =>
  axios.get<IPlayer[]>(`/clans/${clanId}/members`);

export const createClan = ({
  coordinates,
  admin_id,
  name,
  work_area,
}: Pick<IClan, 'coordinates' | 'name' | 'work_area'> & {
  admin_id: string;
}) => {
  const location = coordinates[0].map(([lat, lon]) => ({
    lat,
    lon,
  }));

  return axios.post(
    '/clans',
    {
      name,
      location,
      admin_id,
      work_area,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const updateClan = ({
  coordinates,
  id,
  name,
  count_players,
  work_area,
}: IClan) => {
  const location = coordinates[0].map(([lat, lon]) => ({
    lat,
    lon,
  }));

  return axios.patch(
    `/clans/${id}`,
    {
      name,
      location,
      count_players,
      work_area,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const deleteClan = (id: ID) => axios.delete(`/clans/${id}`);

export const setClanMember = ({
  clanId,
  memberId,
  roleId,
}: TSetClanMemberInput) =>
  axios.put(`/clans/${clanId}/members/${memberId}`, undefined, {
    params: {
      role_id: roleId,
    },
  });

export const deleteClanMember = ({
  clanId,
  memberId,
}: Omit<TSetClanMemberInput, 'roleId'>) =>
  axios.delete(`/clans/${clanId}/members/${memberId}`);

export type TSetClanMemberInput = {
  clanId: ID;
  memberId: ID;
  roleId: string;
  isAddedFromAccount?: boolean;
};
