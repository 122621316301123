import { CSSProperties, FC, MouseEvent, PropsWithChildren } from 'react';
import cn from 'classnames';
import { ReactComponent as Burger } from 'images/newIcons/menu.svg';

export interface LinkProps {
  title?: string;
  className?: string;
  textClassName?: string;
  burgerLeft?: boolean;
  burgerRight?: boolean;
  style?: CSSProperties | undefined;
  type?: LinkButtonType;
  onClick: (event: MouseEvent) => void | ((event: MouseEvent) => Promise<void>);
}

type LinkButtonType = 'submit' | 'reset' | 'button';

export const Link: FC<PropsWithChildren<LinkProps>> = ({
  title,
  children,
  className,
  textClassName,
  onClick,
  burgerLeft = false,
  burgerRight = false,
  style,
  type,
}) => {
  return (
    <button
      className={cn(className)}
      onClick={onClick}
      style={style}
      type={type}
    >
      {title ? (
        <>
          {burgerLeft && <Burger />}
          <span className={cn(textClassName)}>{title}</span>
          {burgerRight && <Burger />}
        </>
      ) : (
        <>{children}</>
      )}
    </button>
  );
};
