import { ForwardedRef, forwardRef, MouseEvent, ReactNode } from 'react';
import cn from 'classnames';
import { ReactComponent as CloseIcon } from 'images/newIcons/close.svg';

interface TagProps {
  label: string;
  children?: ReactNode;
  closeIcon?: boolean;
  className?: string;
  onRemove?: (event: MouseEvent) => void;
  onClick?: (value: string) => void;
}

export const Tag = forwardRef(
  (
    { label, children, onRemove, onClick, closeIcon, className }: TagProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => (
    <div
      ref={ref}
      className={cn(
        'w-max max-h-[24px] flex gap-[4px] items-center px-[16px] py-[5px] bg-dark rounded-[5px] transition-colors select-none',
        onClick && 'cursor-pointer hover:bg-light',
        className
      )}
      onClick={() => onClick?.(label)}
    >
      {children ?? <span className="tpg-c2 whitespace-nowrap">{label}</span>}
      {closeIcon && (
        <div className="group h-[16px] w-[16px] cursor-pointer [&>svg>path]:fill-bright_product hover:[&>svg>path]:fill-tpg_title">
          <CloseIcon
            className="group-hover:[&>path]:fill-tpg_title"
            onClick={onRemove}
          />
        </div>
      )}
    </div>
  )
);

Tag.displayName = 'Tag';
