export const getLStorage = (key: string) => localStorage.getItem(key);

export const setLStorage = ([key, value]: [string, unknown]) => {
  localStorage.setItem(key, String(value));
};

export const setLStorageMany = <T extends Record<string, unknown>>(
  objToSave: T
) => {
  Object.entries(objToSave).forEach(setLStorage);
};

export const clearLStorage = (key: string) => {
  localStorage.removeItem(key);
};

export const clearLStorageMany = (...keys: string[]) => {
  keys.forEach(clearLStorage);
};
