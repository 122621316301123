import { FC, MouseEvent, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useClickOutside } from 'hooks';
import { IOption } from 'interfaces';
import { IClanPlayer } from 'interfaces/player';
import { roleOptions } from 'mock';
import { updateAccountRoleThunk } from 'store/slices/account/actions';
import { deleteClanMemberThunk } from 'store/slices/clan/actions';

import { DeleteModal } from 'components/DeleteModal';
import { Button, Select } from 'components/ui';

interface AccessTableProps extends IClanPlayer {
  accountId: string;
  clanOptions: IOption[];
}

export const AccessTable: FC<AccessTableProps> = ({
  clanId,
  roleId,
  accountId,
  clanOptions,
}) => {
  const roleInitialValue = roleOptions.find(
    (option) => option.value === roleId
  );

  const [selectedClan, setSelectedClan] = useState<IOption>({
    value: '',
    label: '',
  });

  const [selectedRole, setSelectedRole] = useState<IOption>(
    roleInitialValue || {
      value: '',
      label: '',
    }
  );

  useEffect(() => {
    const clanInitialValue = clanOptions.find((clan) => clan.value === clanId);

    if (clanInitialValue) {
      setSelectedClan(clanInitialValue);
    }
  }, [clanOptions, clanId]);

  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [isShowDeleteButton, setShowDeleteButton] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  useClickOutside<HTMLDivElement, void>(containerRef, () =>
    setShowDeleteButton(false)
  );

  const handleRightClick = (e: MouseEvent) => {
    e.preventDefault();

    setShowDeleteButton(true);
  };

  const handleSelectRole = (value: IOption | IOption[]) => {
    if (!Array.isArray(value)) {
      setSelectedRole(value);

      dispatch(
        updateAccountRoleThunk({
          account_id: accountId,
          clan_id: String(clanId),
          role_id: value.value,
        })
      );
    }
  };

  const handleOpenModal = (event: MouseEvent) => {
    event.stopPropagation();

    setOpenDeleteModal(true);
  };

  const handleCloseModal = () => {
    setOpenDeleteModal(false);
  };

  const handleRemoveAccess = () => {
    dispatch(
      deleteClanMemberThunk({
        clanId: String(clanId),
        memberId: accountId,
        isAddedFromAccount: true,
      })
    );

    handleCloseModal();
  };

  return (
    <>
      <div
        ref={containerRef}
        className="border border-solid rounded-[10px] border-dark_product"
        onContextMenu={handleRightClick}
      >
        <div className="relative flex flex-col px-[16px] py-[10px]">
          <span className="tpg-c2 text-tpg_light">Клан</span>
          <span>{selectedClan.label}</span>
          {isShowDeleteButton && (
            <Button
              className="absolute left-[140px] top-[15px] rounded-[10px] border border-solid border-tpg_light bg-ultrablack px-[16px] py-[6px] text-tpg_base hover:bg-dark_product"
              title="Удалить"
              onClick={handleOpenModal}
            />
          )}
        </div>
        <Select
          value={selectedRole}
          options={roleOptions}
          onSelect={handleSelectRole}
          placeholder="Роль"
          className="cursor-pointer pl-[16px] rounded-b-[10px] border-t border-solid border-t-dark_product"
        />
      </div>
      {isOpenDeleteModal && (
        <DeleteModal
          title="Вы уверены, что хотите удалить доступ?"
          onClose={handleCloseModal}
          onRemove={handleRemoveAccess}
        />
      )}
    </>
  );
};
