import { loggedIn, login } from 'api/auth';
import { getRoles } from 'api/role';
import { errorMessages } from 'constants/errors';
import { asyncActionsNames, reducersNames } from 'constants/reducers';
import {
  ILoggedInRequest,
  ILoginResponse,
  TLoggedInResponse,
} from 'interfaces';

import { createThunk } from 'utils';

export const loginThunk = createThunk<ILoginResponse>(
  `${reducersNames.AUTH}/${asyncActionsNames.LOGIN}`,
  login
);

export const loggedInThunk = createThunk<TLoggedInResponse, ILoggedInRequest>(
  `${reducersNames.LOGGED_IN}/${asyncActionsNames.LOGIN}`,
  loggedIn
);

export const getRolesThunk = createThunk(
  `${reducersNames.AUTH}/${asyncActionsNames.GET_ROLES}`,
  getRoles,
  errorMessages.GET_PRIVILEGES_ERROR
);
