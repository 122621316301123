// temporary stream mock images, will be removed in the future
import { EUserRole } from 'constants/profile';
import { IClan } from 'interfaces/clan';
import { EPlayerRole } from 'types/player';

import stream from './images/stream.png';
import stream2 from './images/stream2.png';
import stream3 from './images/stream3.png';
import stream4 from './images/stream4.png';

export const NEW_ID = 'new';

export const mockClan: IClan = {
  id: 322,
  name: 'GGGGGGGGGG',
  computers: [],
  count_players: 3,
  work_area: 'г. Москва, метро Новослободская',
  record: 71,
  players: [],
  longitude: 36.12704879132674,
  latitude: 54.99079688395308,
  coordinates: [
    [
      [35.71212143054848, 54.97993848789625],
      [35.95917287204463, 55.26587790712344],
      [36.512806222868164, 55.10784300806347],
      [36.52471231643466, 54.75726859409926],
      [35.92643111473777, 54.843056422582976],
      [35.71212143054848, 54.97993848789625],
    ],
  ],
  preview_src: stream,
};

export const mockClans: IClan[] = [
  {
    id: 1,
    name: 'Север',
    computers: [],
    count_players: 3,
    work_area: 'г. Москва, метро Новослободская',
    record: 71,
    players: [],
    longitude: 36.12704879132674,
    latitude: 54.99079688395308,
    coordinates: [
      [
        [35.71212143054848, 54.97993848789625],
        [35.95917287204463, 55.26587790712344],
        [36.512806222868164, 55.10784300806347],
        [36.52471231643466, 54.75726859409926],
        [35.92643111473777, 54.843056422582976],
        [35.71212143054848, 54.97993848789625],
      ],
    ],
    preview_src: stream,
  },
  {
    id: 2,
    name: 'Орда',
    computers: [],
    count_players: 3,
    work_area: 'г. Москва, метро Новослободская',
    record: 71,
    players: [],
    longitude: 39.04612436241513,
    latitude: 56.01409766176623,
    coordinates: [
      [
        [38.72195320039356, 56.08675609386523],
        [38.86660623607867, 55.88549910112167],
        [39.274601977752184, 55.88341882883299],
        [39.34507396949704, 56.05363118489217],
        [39.022386428354196, 56.1611831001191],
        [38.72195320039356, 56.08675609386523],
      ],
    ],
    preview_src: stream2,
  },
  {
    id: 3,
    name: 'Раки',
    computers: [],
    count_players: 3,
    work_area: 'г. Москва, метро Новослободская',
    record: 71,
    players: [],
    longitude: 38.04323044382139,
    latitude: 55.01944259104496,
    coordinates: [
      [
        [37.180437114670866, 55.240076014399506],
        [37.60232071761183, 54.70116210106056],
        [38.82247427513386, 54.593468727912835],
        [38.735615886294255, 55.20704806610388],
        [37.87530422539615, 55.35545804574798],
        [37.180437114670866, 55.240076014399506],
      ],
    ],
    preview_src: stream3,
  },
  {
    id: 'for moder',
    name: 'Раки',
    computers: [],
    count_players: 3,
    work_area: 'г. Москва, метро Новослободская',
    record: 71,
    players: [],
    longitude: 36,
    latitude: 55,
    coordinates: [
      [
        [37.774428431387435, 55.133695250518855],
        [38.38160540200528, 55.052564009055175],
        [38.387769635209594, 54.833041417957276],
        [37.94086272790216, 54.81706164589477],
        [37.57409085224904, 55.008401864991754],
        [37.774428431387435, 55.133695250518855],
      ],
    ],
    preview_src: stream,
  },
];

export const newClanInitialState = {
  id: NEW_ID,
  name: '',
  computers: [],
  count_players: 0,
  work_area: '',
  record: 0,
  access: [],
  players: [],
  longitude: 0,
  latitude: 0,
  coordinates: [],
};

export const mockPlayer = {
  id: 1,
  name: 'Бигбой',
  clans: [
    { id: 1, name: 'Раки', role: EPlayerRole.OBSERVER },
    { id: 2, name: 'Орда', role: EPlayerRole.MODERATOR },
  ],
  role: EUserRole.USER,
  status: 'Активный',
  launchedStreams: 32,
  access: ['Орда', 'Север'],
  technique: 'GeForce 456',
  login: 'gorby',
};

export const mockTableData = {
  total_count: mockClans.length,
  documents: mockClans,
};

export const statusOptions = [
  {
    value: 'Активный',
    label: 'Активный',
  },
  {
    value: 'Не авторизован',
    label: 'Не авторизован',
  },
  {
    value: 'Стримит',
    label: 'Стримит',
  },
];

export const techniqueOptions = [
  {
    value: 'tchnq-1',
    label: 'GeForce 456',
  },
  {
    value: 'tchnq-2',
    label: 'GeForce 34',
  },
  {
    value: 'tchnq-3',
    label: 'GeForce 356',
  },
  {
    value: 'tchnq-4',
    label: 'GeForce 3434',
  },
  {
    value: 'tchnq-5',
    label: 'GeForce 3677',
  },
];

export const accessOptions = [
  {
    value: 'Орда',
    label: 'Орда',
  },
  {
    value: 'Север',
    label: 'Север',
  },
  {
    value: 'Раки',
    label: 'Раки',
  },
];

export const mockHistory = [
  {
    date: '3 января 2023 года',
    content: [
      {
        title: 'Игрок бигбой начал стрим',
        time: '12:20',
      },
    ],
  },
  {
    date: '2 января 2023 года',
    content: [
      {
        title: 'Игрок Литлбой закончил стрим',
        time: '12:20',
        description:
          'Оrem ipsum dolor sit amet, consectetur adipiscing elit. Quis lobortis nisl cursus bibendum sit nulla accumsan sodales ornare. At urna viverra non suspendisse neque, lorem. Pretium condimentum pellentesque gravida id',
      },
      {
        title: 'Игрок Литлбой начал стрим',
        time: '09:20',
      },
    ],
  },
];

export const mockInfoData = [
  {
    id: 1,
    name: 'Бигбой',
    clans: [
      { id: 1, name: 'Раки', role: EPlayerRole.OBSERVER },
      { id: 2, name: 'Орда', role: EPlayerRole.MODERATOR },
    ],
    role: EUserRole.USER,
    status: 'Активный',
    launchedStreams: 32,
    access: ['Орда', 'Север'],
    technique: 'GeForce 456',
    login: 'gorby',
  },
  {
    id: 2,
    name: 'Литтлбой',
    clans: [
      { id: 1, name: 'Раки', role: EPlayerRole.OBSERVER },
      { id: 2, name: 'Орда', role: EPlayerRole.MODERATOR },
    ],
    status: 'Активный',
    role: EUserRole.USER,
    launchedStreams: 32,
    access: ['Орда', 'Север'],
    technique: 'GeForce 456',
    login: 'gorby',
  },
  {
    id: 3,
    name: 'Егерь',
    clans: [
      { id: 1, name: 'Раки', role: EPlayerRole.OBSERVER },
      { id: 2, name: 'Орда', role: EPlayerRole.MODERATOR },
    ],
    status: 'Активный',
    role: EUserRole.USER,
    launchedStreams: 32,
    access: ['Орда', 'Север'],
    technique: 'GeForce 456',
    login: 'gorby',
  },
];

export const streamsMock = {
  online: [
    {
      id: 1,
      src: stream,
      time: '00:15',
      tags: [{ id: 1, title: 'горбатый' }],
    },
    {
      id: 2,
      src: stream2,
      time: '00:15',
      tags: [{ id: 1, title: 'горбатый' }],
    },
    {
      id: 3,
      src: stream3,
      time: '00:15',
      tags: [{ id: 1, title: 'горбатый' }],
    },
  ],
  record: {
    video: [
      {
        id: 1,
        src: stream4,
        time: '14:00',
        date: '12.04.2024',
        tags: [
          { id: 1, title: 'горбатый' },
          { id: 2, title: 'матч' },
          { id: 3, title: 'стратегия' },
        ],
      },
      {
        id: 2,
        src: stream4,
        time: '14:00',
        date: '12.04.2024',
        tags: [
          { id: 1, title: 'горбатый' },
          { id: 2, title: 'матч' },
          { id: 3, title: 'стратегия' },
        ],
      },
      {
        id: 3,
        src: stream4,
        time: '14:00',
        date: '12.04.2024',
        tags: [
          { id: 1, title: 'горбатый' },
          { id: 2, title: 'матч' },
          { id: 3, title: 'стратегия' },
        ],
      },
      {
        id: 4,
        src: stream4,
        time: '14:00',
        date: '12.04.2024',
        tags: [
          { id: 1, title: 'горбатый' },
          { id: 2, title: 'матч' },
          { id: 3, title: 'стратегия' },
        ],
      },
    ],
    modes: [
      { id: 1, title: 'Матч' },
      { id: 2, title: 'Прохождение' },
      { id: 3, title: 'Совместная игра' },
    ],
    strategies: [
      { id: 1, title: 'Paradox' },
      { id: 2, title: 'Стратегия' },
      { id: 3, title: 'grand стратегия' },
    ],
    users: [
      { id: 1, title: 'Литлбой' },
      { id: 2, title: 'Сизый' },
      { id: 3, title: 'Горбатый' },
      { id: 4, title: 'Литлбой2' },
      { id: 5, title: 'Сизый2' },
      { id: 6, title: 'Горбатый2' },
      { id: 7, title: 'Литлбой3' },
      { id: 8, title: 'Сизый3' },
      { id: 9, title: 'Горбатый3' },
      { id: 10, title: 'Литлбой4' },
      { id: 11, title: 'Сизый4' },
      { id: 12, title: 'Горбатый4' },
    ],
  },
};

export const clansMock = {
  totalOnline: 3,
  clans: [
    {
      id: 1,
      clan: 'Раки',
      place: 'м. Домодедовская',
      ...streamsMock,
    },
    {
      id: 2,
      clan: 'Север',
      place: 'м. Новословбодская',
      ...streamsMock,
    },
  ],
};

export const streamsCardMock = [
  {
    id: 1,
    src: stream,
    time: '00:15',
    tags: [{ id: 1, title: 'горбатый' }],
  },
  {
    id: 2,
    src: stream2,
    time: '00:15',
    tags: [{ id: 1, title: 'горбатый' }],
  },
  {
    id: 3,
    src: stream3,
    time: '00:15',
    tags: [{ id: 1, title: 'горбатый' }],
  },
  {
    id: 4,
    src: stream4,
    time: '00:15',
    tags: [{ id: 1, title: 'горбатый' }],
  },
];

export const fakeStreamName = 'testtesttesttest';
export const fakeStreamToken = '';

export const roleOptions = [
  {
    value: '00000000000000000000000004',
    label: 'Игрок',
  },
  {
    value: '00000000000000000000000005',
    label: 'Модератор',
  },
  {
    value: '00000000000000000000000006',
    label: 'Админ',
  },
];
